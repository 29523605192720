<div class="logo">
    <a href="#" class="simple-text">
        <div class="logo-img">
            <img src="../../../assets/img/LogoInline.png"/>
        </div>
    </a>
</div>
<div class="sidebar-wrapper">
  <!-- <div *ngIf="isMobileMenu()">
    <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form>
    <ul class="nav navbar-nav nav-mobile-menu">
        <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)">
                <i class="material-icons">dashboard</i>
                <p>
                    <span class="d-lg-none d-md-block">Stats</span>
                </p>
            </a>
        </li>
        <li class="nav-item dropdown">
            <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">notifications</i>
                <span class="notification">5</span>
                <p>
                    <span class="d-lg-none d-md-block">Some Actions</span>
                </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="#">Mike John responded to your email</a>
                <a class="dropdown-item" href="#">You have 5 new tasks</a>
                <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                <a class="dropdown-item" href="#">Another Notification</a>
                <a class="dropdown-item" href="#">Another One</a>
            </div>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)">
                <i class="material-icons">person</i>
                <p>
                    <span class="d-lg-none d-md-block">Account</span>
                </p>
            </a>
        </li>
    </ul>
  </div> -->
  <ul class="nav">
    <ng-container *ngFor="let menuItem of menuItems">
      <ng-container *ngIf="!menuItem.children">
        <li routerLinkActive="active" class="{{menuItem.class}} nav-item">
          <a class="nav-link" [routerLink]="[menuItem.path]">
            <i class="material-icons">{{menuItem.icon}}</i>
            <p>{{menuItem.title}}</p>
          </a>
        </li>
      </ng-container>
      <ng-container *ngIf="menuItem.children">
        <li class="nav-item dropdown">
          <a class="nav-link" (click)="toggleDropdown(menuItem)" style="display: flex; justify-content: space-between; align-items: center;">
            <span>
              <i *ngIf="menuItem.title!='Dr.Mo'" class="material-icons">{{menuItem.icon}}</i>
              <img *ngIf="menuItem.title=='Dr.Mo'" src="assets/img/Dr._Mo2-removebg.png" alt="AI Assistant" class="navbar-icon">
            <p>{{menuItem.title}}</p>
            </span>
            <i class="material-icons dropdown-icon">{{menuItem.open ? 'expand_less' : 'expand_more'}}</i>
          </a>
          <ul class="nav-dropdown" *ngIf="menuItem.open">
            <li *ngFor="let child of menuItem.children" routerLinkActive="active">
              <a class="nav-link" [routerLink]="[child.path]">
                <i class="material-icons">{{child.icon}}</i>
                <p>{{child.title}}</p>
              </a>
            </li>
          </ul>
        </li>
      </ng-container>
    </ng-container>
  
    <li class="nav-item" *ngIf="isMobileMenu()">
      <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLinkaccount" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="material-icons">person</i>
        <p>
          <span class="d-lg-none d-md-block">Account</span>
        </p>
      </a>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLinkaccount">
        <a class="dropdown-item" (click)="GotoChangePassword()">change password</a>
        <a class="dropdown-item"(click)="logout()">log out</a>
      </div>
    </li>
  </ul>
</div>
