import { Injectable } from '@angular/core';

import { Globals } from '../globals';
import { AngularFirestore, AngularFirestoreCollection, Query, QueryFn} from '@angular/fire/compat/firestore';
import { modules } from '../models/modules.model';
import { clients } from '../models/clients.model';
import { AuthService } from './auth.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { Observable, catchError, from, map, of, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase/app';
import { promise } from 'selenium-webdriver';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { QueryDocumentSnapshot } from '@angular/fire/compat/firestore/interfaces';
import { resolve } from 'path';
import { query } from 'chartist';
import { firstValueFrom } from 'rxjs'; // Import this to use firstValueFrom

import { LoaderServiceService } from './loaderService.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';


@Injectable({
  providedIn: "root"

})

export class GeneralService {
  moduleref: any[] = [];
  image: any[] = [];
  client: any;
  currentuser: any;
  doc: any;
  firstInResponse: any = [];
  lastInResponse: any = [];
  prev_strt_at: any = [];
  pagination_clicked_count = 0;
  disable_next: boolean = false;
  disable_prev: boolean = true;
  constructor(private storage: AngularFireStorage, private AngularFirestore: AngularFirestore,
    private LoaderServiceService: LoaderServiceService, private afAuth: AngularFireAuth, public datepipe: DatePipe, private AuthService: AuthService, private firestore: AngularFirestore, private globals: Globals, private http: HttpClient) {

     }
  listofmodules() {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('modules').get().subscribe((client) => {
        var data = client.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();
      }, error => {
        reject(error);
        this.LoaderServiceService.hide();
      });
    });
  }

  download(url: string): Observable<Blob> {
    console.log(url);
    return this.http.get(url, {
      responseType: 'blob'
    })
  }


  // async addclient(client) {
  //  this.LoaderServiceService.show();
  //  this.AngularFirestore.firestore['_databaseId'].database=await "(default)"

  //   client.passwordChanged=false
  //   return new Promise<any>((resolve, reject) => {
  //     this.AuthService.emailSignup(client.email, 'Aa123456')
  //       .then(async (value) => {
  //         console.log(value);

  //         // Add the client document to Firestore
  //         this.firestore.collection('clients').add(client).then(async res => {

  //           // Extract the ID of the added document
  //           const clientId = res.id;

  //           // Update the client document with the generated ID
  //           this.firestore.collection('clients').doc(clientId).update({ clientId: clientId, id: res.id })
  //             .then(() => {
  //               // Add user with client's email and clientId
  //               // this.AuthService.adduser({ email: client.email, role: "cluster", clientid: clientId });


  //               resolve(res);
  //               this.LoaderServiceService.hide();
  //               // Resolve the promise with the document reference
  //             })
  //             .catch(error => {
  //               reject(error);
  //               this.LoaderServiceService.hide();

  //             });


  //         })
  //           .catch(error => {
  //             reject(error);
  //             this.LoaderServiceService.hide();

  //           });
  //       })
  //       .catch(error => {
  //         Swal.fire({
  //           title: 'Warning!',
  //           text: 'Email Registered Before',
  //           icon: 'error'
  //         });
  //         console.log('Something went wrong: ', error);
  //         reject(error); // Reject the promise if an error occurs
  //       });
  //       this.LoaderServiceService.hide();
  //     });
  // }

  async addclient(client: any, id) {
    this.LoaderServiceService.show();
    this.AngularFirestore.firestore['_databaseId'].database = await "(default)";

    client.passwordChanged = false;

    return new Promise<any>((resolve, reject) => {
      this.AuthService.emailSignup(client.email, 'Aa123456')
        .then(async (value) => {
          console.log(value);

          const clientId = id; // Example: Custom ID logic
      
                this.LoaderServiceService.hide(); // Show the loader before making the request
                this.firestore.collection('clients').doc(clientId).set(client)
                  .then(async (res) => {
                    this.LoaderServiceService.hide();
                    resolve(res)
                  })
                  .catch(error => {
                    reject(error);
                    this.LoaderServiceService.hide();
                  });
             
        
            // Add user with client's email and clientId
            // this.AuthService.adduser({ email: client.email, role: "cluster", clientid: clientId });


          }).catch(error => {
            reject(error);
            this.LoaderServiceService.hide();
          })

        .catch(error => {
          Swal.fire({
            title: 'Warning!',
            text: 'Email Registered Before',
            icon: 'error'
          });
          console.log('Something went wrong: ', error);
          reject(error); // Reject the promise if an error occurs
        });
      this.LoaderServiceService.hide();
    });
  }



  async addclienttoanotherdb(client) {
    console.log(client);
    this.LoaderServiceService.show();
    client.passwordChanged = false
    this.AngularFirestore.firestore['_databaseId'].database = await client.database
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('clients').add(client).then(async res => {
        const clientId = res.id;
        this.firestore.collection("users").add({ email: client.email, role: "cluster", clientid: clientId })
          .then((value) => {
            this.LoaderServiceService.hide(); 
            const qutapackage  = {
              clientid:clientId,
              qutausers:client.countusers,
              qutabranches:client.countbranches,
              qutaaudits:client.countaudits,
              countusers:1,
              countbranches:0,
years:[
 
]
            }
            this.firestore.collection('quotapackage').add(qutapackage).then(async resault => {
              this.firestore.collection('clients').doc(res.id).update({ clientId: clientId, id: res.id })
              .then(async () => {
                this.LoaderServiceService.hide();
                resolve(res)
              })
              .catch(error => {
                reject(error);
                this.LoaderServiceService.hide();
              });
            })

          
          })
          .catch((error) => {
            this.LoaderServiceService.hide(); // Show the loader before making the request
            console.log("Something went wrong: ", error);
          });
      })
        .catch(error => {
          reject(error);
          this.LoaderServiceService.hide();

        });


    });
  }

















  //  async addclient(client) {
  //     return new Promise<any>((resolve, reject) => {          
  //         this.AuthService.emailSignup(client.email,'Aa123456')
  //           .then((value) => {
  //               console.log(value)
  //         this.firestore.collection('clients').add(client).then(res=>{
  //                   this.AuthService.adduser({email:client.email,role:"cluster",clientid:res.id});
  //                   resolve(res);
  //               }, error => {
  //                   reject(error);
  //                 });            


  //           })
  //           .catch(error => {
  //               Swal.fire({
  //                   title: 'Warning!',
  //                   text: 'Email Registerd Before',
  //                   icon: 'error'
  //                 }) 
  //             console.log('Something went wrong: ', error);
  //           });
  //       });
  //   }
  async addClusterForClient(Cluster) {
    Cluster.passwordChanged = false
console.log(Cluster);
    return new Promise<any>((resolve, reject) => {
      this.qutaCountAddUsers(Cluster.clientid).subscribe(success => {
if(success){
  this.LoaderServiceService.show();
  this.AuthService.emailSignup(Cluster.email, 'Aa123456')
        .then((value) => {
       
              this.firestore.collection('users').add(Cluster).then(res => {
                resolve(res);
                this.LoaderServiceService.hide();
    
              }, error => {
                reject(error);
                this.LoaderServiceService.hide();
    
              });
            
        })
        .catch(error => {
          Swal.fire({
            title: 'Warning!',
            text: 'Email Registerd Before',
            icon: 'error'
          })
          console.log('Something went wrong: ', error);
        });
}

      })
    

    });

  }

  async addGMForClient(GM) {
    GM.passwordChanged = false

    return new Promise<any>((resolve, reject) => {
      this.qutaCountAddUsers(GM.clientid).subscribe(success => {
        if(success){
          this.LoaderServiceService.show();
          this.AuthService.emailSignup(GM.email, 'Aa123456')
          .then((value) => {
            console.log(value)
            this.firestore.collection('users').add(GM).then(res => {
              console.log(res);
              resolve(res);
              this.LoaderServiceService.hide();
  
            }, error => {
              reject(error);
              this.LoaderServiceService.hide();
  
            });
          })
          .catch(error => {
            Swal.fire({
              title: 'Warning!',
              text: error,
              icon: 'error'
            })
            console.log('Something went wrong: ', error);
          });
        }});
 

    });
  }

  async addCeoForClient(Ceo) {
    Ceo.passwordChanged = false

    return new Promise<any>((resolve, reject) => {

      this.qutaCountAddUsers(Ceo.clientid).subscribe(success => {
        if(success){ 
        this.LoaderServiceService.show();
        this.AuthService.emailSignup(Ceo.email, 'Aa123456')
        .then((value) => {
          console.log(value)
          this.firestore.collection('users').add(Ceo).then(res => {
            console.log(res);
            resolve(res);
            this.LoaderServiceService.hide();

          }, error => {
            reject(error);
            this.LoaderServiceService.hide();

          });
        })
        .catch(error => {
          Swal.fire({
            title: 'Warning!',
            text: error,
            icon: 'error'
          })
          console.log('Something went wrong: ', error);
        });

        }});
   

    });
  }

  async addAuditerForClient(Auditer) {
    Auditer.passwordChanged = false

    return new Promise<any>((resolve, reject) => {
      this.qutaCountAddUsers(Auditer.clientid).subscribe(success => {
        if(success){
          this.LoaderServiceService.show();
          this.AuthService.emailSignup(Auditer.email, 'Aa123456')
          .then((value) => {
            console.log(value)
            this.firestore.collection('users').add(Auditer).then(res => {
              resolve(res);
              this.LoaderServiceService.hide();
            }, error => {
              reject(error);
              this.LoaderServiceService.hide();
            });
          })
          .catch(error => {
            Swal.fire({
              title: 'Warning!',
              text: 'Email Registerd Before',
              icon: 'error'
            })
            console.log('Something went wrong: ', error);
          });
         }});


    });
  }

  async addMeasurerForClient(Measurer) {
    Measurer.passwordChanged = false
    return new Promise<any>((resolve, reject) => {
      this.qutaCountAddUsers(Measurer.clientid).subscribe(success => {
        if(success){   
           this.LoaderServiceService.show();
          this.AuthService.emailSignup(Measurer.email, 'Aa123456')
          .then((value) => {
            console.log(value)
            this.firestore.collection('users').add(Measurer).then(res => {
              resolve(res);
              this.LoaderServiceService.hide();
  
            }, error => {
              reject(error);
              this.LoaderServiceService.hide();
  
            });
  
  
          })
          .catch(error => {
            Swal.fire({
              title: 'Warning!',
              text: 'Email Registerd Before',
              icon: 'error'
            })
            console.log('Something went wrong: ', error);
          });
        }});


    });
  }
  async addMeasurerItemsForClient(item) {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('MeasurersItems').add(item).then(res => {
        resolve(res);
        this.LoaderServiceService.hide();
      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  async addMeasurerValueForClient(item) {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('Measurers').add(item).then(res => {
        resolve(res);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  async UpdateMeasurerItems(id, item) {
    this.LoaderServiceService.show();

    this.firestore.collection('MeasurersItems').doc(id).update(item)
      .then(value => {
        this.LoaderServiceService.hide();

        console.log(value);
      })
      .catch(error => {
        this.LoaderServiceService.hide();

        console.log('Something went wrong: ', error);
      });
  }




  async getMeasurerItemsbyclientid(clientId): Promise<any> {
    this.LoaderServiceService.show();
    const userInfo = JSON.parse(localStorage.getItem("currentuser"));
    console.log(userInfo);
    if (userInfo.role == "cluster" || userInfo.role == "ceo") {
      return new Promise<any>((resolve, reject) => {
        this.firestore.collection('MeasurersItems', ref => ref.where('clientid', '==', clientId)).get().subscribe((client) => {
          var data = client.docs.map(element => {
            var item = element.data() as any;
            item.id = element.id;
            return item;
          })
          resolve(data);
          this.LoaderServiceService.hide();

        }, error => {
          reject(error);
          this.LoaderServiceService.hide();

        });

      });




    }
    else {
      let promises;
      promises = userInfo.branchesid.map(branch => {
        return this.firestore.collection('MeasurersItems', ref => {
          let query = ref.where('clientid', '==', clientId)
            .where('Branch', '==', branch)
          return query;
        }).get().toPromise();
      });



      try {
        let fullobject = [];
        const results = await Promise.all(promises);
        const data = results.map(res => {
          return res.docs.map(element => {

            var item = element.data();
            console.log(item);

            item.id = element.id;
            fullobject.push(item);
            return item;
          });
        });
        console.log(data);
        this.LoaderServiceService.hide();

        return fullobject;
      } catch (error) {
        this.LoaderServiceService.hide();

        console.error('Error fetching or processing data:', error);
        throw error;
      }


    }














  }


  async GetMeasurerByMonth(SearchItem): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('Measurers', ref => ref.where('clientid', '==', SearchItem.clientid).where('Month', '==', SearchItem.Month).where('Year', '==', SearchItem.Year).where('Branch', '==', SearchItem.Branch).where('Item', '==', SearchItem.Item)).get().subscribe((client) => {
        var data = client.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }


  async DeleteMeasurerItems(id) {
    this.LoaderServiceService.show();

    this.firestore.collection("MeasurersItems").doc(id).delete().then(value => {
      this.LoaderServiceService.hide();

      console.log(value)
    })
      .catch(error => {
        this.LoaderServiceService.hide();
        console.log('Something went wrong: ', error);
      });

  }


  async UpdateCluster(cluster, id, OldEmail) {
    this.LoaderServiceService.show();

    return new Promise<any>(async (resolve, reject) => {

      if (!OldEmail) {
        cluster.passwordChanged = false
        this.AuthService.emailSignup(cluster.email, 'Aa123456')
          .then((value) => {
            console.log(value)
            this.currentuser = JSON.parse(localStorage.getItem("currentuser"))
            console.log("currentuser", this.currentuser.clientid)
            this.firestore.collection('users').doc(id).update(cluster)
              .then(value => {
                resolve(value)
                this.LoaderServiceService.hide();

              })
              .catch(error => {
                console.log('Something went wrong: ', error);
                reject(error);
                this.LoaderServiceService.hide();
                // Added reject to handle errors
              });
          })
          .catch(error => {
            Swal.fire({
              title: 'Warning!',
              text: 'Email Registered Before',
              icon: 'error'
            })
            console.log('Something went wrong: ', error);
            reject(error);
            this.LoaderServiceService.hide();
            // Added reject to handle errors
          });
      } else {
        this.firestore.collection('users').doc(id).update(cluster)
          .then(value => {
            resolve(value)
            this.LoaderServiceService.hide();

          })
          .catch(error => {
            console.log('Something went wrong: ', error);
            reject(error);
            this.LoaderServiceService.hide();
            // Added reject to handle errors
          });
      }


    });
  }

  async UpdateGM(gm, id, OldEmail) {
    this.LoaderServiceService.show();

    return new Promise<any>(async (resolve, reject) => {

      if (!OldEmail) {
        gm.passwordChanged = false
        this.AuthService.emailSignup(gm.email, 'Aa123456')
          .then((value) => {
            console.log(value);
            this.currentuser = JSON.parse(localStorage.getItem("currentuser"));
            console.log("currentuser", this.currentuser.clientid);
            this.firestore.collection('users').doc(id).update(gm)
              .then(value => {
                resolve(value);
                this.LoaderServiceService.hide();

              })
              .catch(error => {
                console.log('Something went wrong: ', error);
                reject(error);
                this.LoaderServiceService.hide();
                // Handle errors
              });
          })
          .catch(error => {
            Swal.fire({
              title: 'Warning!',
              text: 'Email Registered Before',
              icon: 'error'
            });
            console.log('Something went wrong: ', error);
            reject(error);
            this.LoaderServiceService.hide();
            // Handle errors
          });
      } else {
        this.firestore.collection('users').doc(id).update(gm)
          .then(value => {
            resolve(value);
            this.LoaderServiceService.hide();

          })
          .catch(error => {
            console.log('Something went wrong: ', error);
            reject(error);
            this.LoaderServiceService.hide();
            // Handle errors
          });
      }




    });
  }
  async UpdateCeo(ceo, id, OldEmail) {
    this.LoaderServiceService.show();

    return new Promise<any>(async (resolve, reject) => {

      if (!OldEmail) {
        ceo.passwordChanged = false
        this.AuthService.emailSignup(ceo.email, 'Aa123456')
          .then((value) => {
            console.log(value);
            this.currentuser = JSON.parse(localStorage.getItem("currentuser"));
            console.log("currentuser", this.currentuser.clientid);
            this.firestore.collection('users').doc(id).update(ceo)
              .then(value => {
                resolve(value);
                this.LoaderServiceService.hide();

              })
              .catch(error => {
                console.log('Something went wrong: ', error);
                reject(error);
                this.LoaderServiceService.hide();
                // Handle errors
              });
          })
          .catch(error => {
            Swal.fire({
              title: 'Warning!',
              text: 'Email Registered Before',
              icon: 'error'
            });
            console.log('Something went wrong: ', error);
            reject(error);
            this.LoaderServiceService.hide();
            // Handle errors
          });
      } else {
        this.firestore.collection('users').doc(id).update(ceo)
          .then(value => {
            resolve(value);
            this.LoaderServiceService.hide();

          })
          .catch(error => {
            console.log('Something went wrong: ', error);
            reject(error);
            this.LoaderServiceService.hide();
          });
      }

    });
  }


  async UpdateAuditer(Auditer, id, OldEmail) {
    this.LoaderServiceService.show();

    return new Promise<any>(async (resolve, reject) => {

      if (!OldEmail) {
        Auditer.passwordChanged = false

        this.AuthService.emailSignup(Auditer.email, 'Aa123456')
          .then((value) => {
            console.log(value);
            this.currentuser = JSON.parse(localStorage.getItem("currentuser"));
            console.log("currentuser", this.currentuser.clientid);
            this.firestore.collection('users').doc(id).update(Auditer)
              .then(value => {
                resolve(value);
                this.LoaderServiceService.hide();
              })
              .catch(error => {
                console.log('Something went wrong: ', error);
                reject(error);
                this.LoaderServiceService.hide();
              });
          })
          .catch(error => {
            Swal.fire({
              title: 'Warning!',
              text: 'Email Registered Before',
              icon: 'error'
            });
            console.log('Something went wrong: ', error);
            reject(error);
            this.LoaderServiceService.hide();
            // Handle errors
          });
      } else {
        this.firestore.collection('users').doc(id).update(Auditer)
          .then(value => {
            resolve(value);
            this.LoaderServiceService.hide();

          })
          .catch(error => {
            console.log('Something went wrong: ', error);
            reject(error);
            this.LoaderServiceService.hide();
            // Handle errors
          });
      }



    });
  }
  async UpdateMeasurer(Measurer, id, OldEmail) {
    this.LoaderServiceService.show();

    return new Promise<any>(async (resolve, reject) => {
      if (!OldEmail) {
        Measurer.passwordChanged = false
        this.AuthService.emailSignup(Measurer.email, 'Aa123456')
          .then((value) => {
            console.log(value);
            this.currentuser = JSON.parse(localStorage.getItem("currentuser"));
            console.log("currentuser", this.currentuser.clientid);
            this.firestore.collection('users').doc(id).update(Measurer)
              .then(value => {
                resolve(value);
                this.LoaderServiceService.hide();

              })
              .catch(error => {
                console.log('Something went wrong: ', error);
                reject(error);
                this.LoaderServiceService.hide();
                // Handle errors
              });
          })
          .catch(error => {
            Swal.fire({
              title: 'Warning!',
              text: 'Email Registered Before',
              icon: 'error'
            });
            console.log('Something went wrong: ', error);
            reject(error);
            this.LoaderServiceService.hide();
            // Handle errors
          });
      } else {
        this.firestore.collection('users').doc(id).update(Measurer)
          .then(value => {
            resolve(value);
            this.LoaderServiceService.hide();

          })
          .catch(error => {
            console.log('Something went wrong: ', error);
            reject(error);
            this.LoaderServiceService.hide();
            // Handle errors
          });
      }

    });
  }
  async updateassignuser(assignuser, id, OldEmail) {
    this.LoaderServiceService.show();

    return new Promise<any>(async (resolve, reject) => {
      if (!OldEmail) {
        assignuser.passwordChanged = false
        this.AuthService.emailSignup(assignuser.email, 'Aa123456')
          .then((value) => {
            console.log(value)
            this.currentuser = JSON.parse(localStorage.getItem("currentuser"))
            console.log("currentuser", this.currentuser.clientid)
            this.firestore.collection('users').doc(id).update(assignuser)
              .then(value => {
                resolve(value)
                this.LoaderServiceService.hide();

              })
              .catch(error => {
                console.log('Something went wrong: ', error);
                this.LoaderServiceService.hide();

              });


          })
          .catch(error => {
            Swal.fire({
              title: 'Warning!',
              text: 'Email Registerd Before',
              icon: 'error'
            })
            console.log('Something went wrong: ', error);
            this.LoaderServiceService.hide();

          });
      }
      else {
        this.firestore.collection('users').doc(id).update(assignuser)
          .then(value => {
            resolve(value)
            this.LoaderServiceService.hide();

          })
          .catch(error => {
            console.log('Something went wrong: ', error);
            this.LoaderServiceService.hide();

          });
      }


    });
  }
  async UpdateUserPasswordChangedByEmail(email: string) {
    this.LoaderServiceService.show();

    return new Promise<any>(async (resolve, reject) => {
      try {
        const querySnapshot = await this.firestore.collection('users', ref => ref.where('email', '==', email)).get().toPromise();

        if (!querySnapshot.empty) {
          const docId = querySnapshot.docs[0].id;

          await this.firestore.collection('users').doc(docId).update({
            passwordChanged: true
          });

          resolve('Password change updated successfully');
          this.LoaderServiceService.hide();

        } else {
          reject('No user found with the provided email');
          this.LoaderServiceService.hide();

        }
      } catch (error) {
        console.log('Something went wrong: ', error);
        reject(error);
        this.LoaderServiceService.hide();

      }

    });
  }


  UpdateBranch(branch, id) {
    this.LoaderServiceService.show();

    console.log(id);
    console.log(branch);

    return this.firestore.collection('branches').doc(id).update(branch)
      .then(value => {
        this.LoaderServiceService.hide();

      })
      .catch(error => {
        this.LoaderServiceService.hide();

        console.log('Something went wrong: ', error);
      });

  }




  
 




  DelBranch(item) {
    this.currentuser = JSON.parse(localStorage.getItem("currentuser"));
    
    return this.qutaCountDelBranches(this.currentuser.clientid).pipe(
      switchMap(success => {
        if (success) {
          this.LoaderServiceService.show();
          return this.firestore.collection("branches").doc(item).delete().then(() => {
            this.LoaderServiceService.hide();
            return true; // Return true on success
          }).catch(error => {
            this.LoaderServiceService.hide();
            console.log('Something went wrong: ', error);
            return false; // Return false on error
          });
        } else {
          return of(false); // Return false if quota check fails
        }
      })
    );
  }



  async addBranchForClient(Branch) {

    return new Promise<any>((resolve, reject) => {
      this.qutaCountAddBranches(Branch.clientid).subscribe(success => {
        if(success){
          this.LoaderServiceService.show();
          this.firestore.collection('branches').add(Branch).then(res => {
            resolve(res);
            this.LoaderServiceService.hide();
          }, error => {
            reject(error);
            this.LoaderServiceService.hide();
    
          });
        }});
   

    });
  }




  async updateclient(client, id) {
    this.LoaderServiceService.show();
    client.passwordChanged = false;
    return new Promise<any>(async (resolve, reject) => {
      this.firestore.collection('clients').doc(id).set(client)
        .then(async (res) => {
          this.LoaderServiceService.hide();
          resolve(res);
        })
        .catch(error => {
          // Handle error in updating document
          reject('Error updating document: ' + error);
          this.LoaderServiceService.hide();
        });
    });
    // await this.firestore.collection('clients').doc(id).update(client);









  }



  // async updateclientanotherdb(client: any, id: string) {
  //   this.AngularFirestore.firestore['_databaseId'].database = await client.database;
  // console.log(client);
  //   // Show the loader
  //   this.LoaderServiceService.show();
  //   return new Promise<any>(async (resolve, reject) => {
  //     this.firestore.collection('clients').doc(id).update(client)
  //       .then(async (res) => {
  //         this.LoaderServiceService.hide();
  //             resolve(res);
  //       })
  //       .catch(error => {
  //         // Handle error in updating document
  //         reject('Error updating document: ' + error);
  //         this.LoaderServiceService.hide();
  //       });
  //   });
  // }


  async updateclientanotherdb(client: any, id: string) {
    console.log(client);
    this.LoaderServiceService.show();
    // client.passwordChanged = false
    this.AngularFirestore.firestore['_databaseId'].database = await client.database
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('quotapackage', ref => ref.where('clientid', '==',client.id)).get().subscribe((clientquta) => {
        console.log(clientquta)
   const data   = clientquta.docs[0].data() as any;
   data.qutausers = client.countusers
   data.qutabranches =  client.countbranches
   data.qutaaudits =  client.countaudits

        this.firestore.collection('quotapackage').doc(clientquta.docs[0].id).set(data
        ).then(async quta => {
          this.firestore.collection('clients').doc(id).set(client).then(async res => {

            resolve(res);
            this.LoaderServiceService.hide();
    
          }).catch(error => {
            reject(error);
            this.LoaderServiceService.hide();
          });
        });
        });

       


    

      // this.firestore.collection('edits').add(client).then(async res => {
      //   this.firestore.collection('clients').doc(id).update(client)
      //   .then(async () => {
      //     this.LoaderServiceService.hide();
      //     resolve(res)
      //   })
      //   .catch(error => {
      //     reject(error);
      //     this.LoaderServiceService.hide();
      //   });
      // })
      //   .catch(error => {
      //     reject(error);
      //     this.LoaderServiceService.hide();

      //   });


    });
  }



  async updateaudit(audits, id) {
    this.LoaderServiceService.show();

    this.firestore.collection('audits').doc(id).update(audits)
      .then(value => {
        this.LoaderServiceService.hide();

        console.log(value);
      })
      .catch(error => {
        this.LoaderServiceService.hide();

        console.log('Something went wrong: ', error);
      });
  }
  async updateActionPlan(actionplan, id) {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('actionplan').doc(id).update(actionplan)
        .then(value => {
          resolve(value)
          this.LoaderServiceService.hide();

          console.log(value);
        }, error => {
          reject(error);
          this.LoaderServiceService.hide();

        })
        .catch(error => {
          console.log('Something went wrong: ', error);
          this.LoaderServiceService.hide();

        });

    })
  }

  async update(actionplan, id) {
    this.LoaderServiceService.show();

    this.firestore.collection('actionplan').doc(id).update(actionplan)
      .then(value => {
        this.LoaderServiceService.hide();

        console.log(value);
      })
      .catch(error => {
        this.LoaderServiceService.hide();

        console.log('Something went wrong: ', error);
      });
  }
  async addassignuser(assignuser) {
    console.log(assignuser)
    assignuser.passwordChanged = false

    return new Promise<any>((resolve, reject) => {
      this.qutaCountAddUsers(assignuser.clientid).subscribe(success => {
        if(success){
          this.LoaderServiceService.show();
          this.AuthService.emailSignup(assignuser.email, 'Aa123456')
          .then((value) => {
            console.log(value)
            this.firestore.collection('users').add(assignuser).then(res => {
              resolve(res);
              this.LoaderServiceService.hide();
  
            }, error => {
              reject(error);
              this.LoaderServiceService.hide();
  
            });
          })
          .catch(error => {
            Swal.fire({
              title: 'Warning!',
              text: 'Email Registerd Before',
              icon: 'error'
            })
            console.log('Something went wrong: ', error);
            this.LoaderServiceService.hide();
  
          });
        }});
      
 

    });

  }


  updatemodule() {
    this.LoaderServiceService.show();

    var obj2 =
    {
      "name": "Food Safety",
      "desc": "to manage our foods saftey",
      "iconios": "ios-restaurant",
      "iconmd": "md-restaurant",
      "categories": [
        {
          "name": "infrastructure",
          "questions": [
            {
              "arabicname": "هل الحوائط و الارضيات نظيفة وفي حالة جيدة ",
              "englishname": "are walls and doors clean and in good condition ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["نظيفة و في حالة جيدة"],
                  "englishchoices": ["clean and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["dirty", "poor condition"],
                  "englishchoices": ["english choice", "english choice"],
                  "score": 0
                },
                {
                  "name": "na"
                }
              ]
            },
            {
              "arabicname": "هل الارضيات نظيفة و في حالة جيدة ",
              "englishname": "are floors clean and in good condition ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["نظيفة و في حالة جيدة"],
                  "englishchoices": ["clean and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["متسخة", "في حالة سيئة"],
                  "englishchoices": ["dirty", "poor condition"],
                  "score": 0
                }
                ,

                {
                  "name": "na"
                }
              ]

            },
            {
              "arabicname": "هل الاسقف جيدة و في حالة جيدة  ",
              "englishname": "Is ceiling clean and in good condition ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["نظيفة و في حالة جيدة"],
                  "englishchoices": ["clean and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["متسخة", "في حالة سيئة", "يوجد تسريب من السقف", "قشور من السقف"],
                  "englishchoices": ["dirty", "poor condition", "evidence of leakage", "paint flecks"],
                  "score": 0
                }
                ,
                {
                  "name": "na"
                }
              ]
            },
            {
              "arabicname": "هل الاضاءة في منطقة غسيل الاطباق و الاواني كافية و اللمبات مغطاة ",
              "englishname": "is lighting in dishwashing area sufficient and light tubes covered ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["الاضاءة كافية و اللمبات مغطاة"],
                  "englishchoices": ["sufficient and light tubes covered"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["الاضاءة غير كافية", "المنطقة مظلمة جدا", "بعض اللمبات غير مغطاة", "كل اللمبات غير مغطاة"],
                  "englishchoices": ["not sufficient", "very dark area", "some light tubes not covered", "all light tubes not covered"],
                  "score": 0
                },
                {
                  "name": "na"
                }
              ]
            },
            {
              "arabicname": "هل التهوية في المنطقة مناسبة و شفاط الابخرة موجود و نظيف و في حالة جيده ",
              "englishname": "is ventilation sufficient and hood available, clean and in good condition ",
              "answers": [
                {
                  "name": "c",

                  "arabicchoices": ["مناسب و شفاط الابخرة جيد"],
                  "englishchoices": ["sufficient and proper"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["التهوية سيئة", "لا يوجد شفاط ابخرة", "شفاط الابخرة في حالة سيئة", "شفاط الابخرة متسخة و عليه تراكمات"],
                  "englishchoices": ["poor ventilation", "hood have built up", "hood in poor condition", "no hood"],
                  "score": 0
                },
                {
                  "name": "na"
                }
              ]
            },
            {
              "arabicname": "هل الصرف مصمم بشكل جيد و نظيف و في حالة جيدة",
              "englishname": "are drains properly designed, clean and in good condition",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["نظيفة و في حالة جيدة"],
                  "englishchoices": ["clean and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",

                  "arabicchoices": ["الصرف متسخ للغاية", "الصرف به انسداد", "لا يوجد صرف", "الصرف في حالة سيئة و الميول غير منضبطة"],
                  "englishchoices": ["heavy soiled", "blocked", "poor design and slope", "no drainage"],
                  "score": 0
                },
                {
                  "name": "na"
                }
              ]
            }
          ]
        },
        {
          "name": "preparatory process",

          "questions": [
            {
              "arabicname": "هل عملية ازالة البقايا تتم بشكل مناسب ",
              "englishname": "is hand-scarping process carried out properly ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["تتم بشكل مناسب وصحيح"],
                  "englishchoices": ["proper hand-scraping"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["لا تتم بشكل مناسب ", "لا تتم اطلاقا"],
                  "englishchoices": ["improper hand scraping", "no hand scraping carried out"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل يتم استخدام ادوات ازالة البقايا تسبب تجريح او خشنة ",
              "englishname": "are scouring pads or other abrasive items used for scraping ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["تستخدم ادوات مناسبة غير خشنة"],
                  "englishchoices": ["non-abrasive pads used"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["تستخدم ادوات خشنة"],
                  "englishchoices": ["scouring pads or other abrasive items used"],
                  "score": 0
                },
                { "name": "na" }

              ]
            },
            {
              "arabicname": "هل تتم عملية النقع بشكل مناسب و بطريقة صحيحة",
              "englishname": "is soaking process performed in right way",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["تتم بطريقة صحيحة"],
                  "englishchoices": ["appropriate "],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["لا تتم عملية النقع نهائيا", "يتم النقع ولكن ليس بطريقة صحيحة"],
                  "englishchoices": ["no soaking", "soaking done but not at right way"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل  عملية التجميع و الترتيب تتم بشكل مناسب طبقا للمعايير",
              "englishname": "is decoy system and sorting carried out well according to standard",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["تتم بطريقة صحيحة"],
                  "englishchoices": ["appropriate"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["لا تتم عملية التجميع او الترتيب نهائيا", "يتم التجميع و الترتيب ولكن ليس بطريقة صحيحة"],
                  "englishchoices": ["decoy and sorting not done at all", " improper decoy and sorting "],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل عملية التسكين في الراكات بشكل صحيح و طبقا للمعايير",
              "englishname": "is racking performed in right way and according to standard ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["تتم بطريقة صحيحة"],
                  "englishchoices": ["appropriate"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["تسكين احجام وانواع مختلفة معا ", "ادخال الاطباق بتجاه معاكس غير مواجهة للماكينة", "يتم تسكين الاطباق الظهر بالظهر", "يتم وضع الفناجين و الاكواب مفتوحة لاعلي", "يتم تسكين البولات فوق بعضها", "تسكين السكاكين و الشوك الجزء المستخدم لاسفل"],
                  "englishchoices": ["racking different size and type together", "run plate sideway from machine", "plates nested back to back", "cup placed up-side open", "bowels placed over each other", "cutlery with eating end facing down"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل السكاكين و الشوك و الملاعق يتم نقعها جيدا قبل دخولها للماكينة ",
              "englishname": "are cutlery pre-soaked prior to enter machine ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["يتم نقعها جيدا"],
                  "englishchoices": ["cutlery pre-soaked properly"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["يتم النقع بشكل غير ملائم ", "لا يتم النقع نهائيا"],
                  "englishchoices": ["improper soaking", "not soaked at all"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل الاغراض يتم شطفها الاولي باستخدام رشاش قبل دخولها للماكينة",
              "englishname": "are wares pre-rinse using overhead sprayer before entering machine",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["يتم شطفها جيدا"],
                  "englishchoices": ["washed properly"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["لا يتم الشطف الاولي", "رشاش المياه ملامس لصرف الحوض", "ضغط مياه رشاش الشطف الاولي ضعيف"],
                  "englishchoices": ["no pre-rinse", "overhead sprayer touch sink drain", "pre-rinse flow pressure too low"],
                  "score": 0
                },
                { "name": "na" }
              ]
            }
          ]
        },

        {
          "name": "dishwashing machine",
          "questions": [
            {
              "arabicname": "هل ستائر ماكينة الغسيل متوفرة وفى حالة جيدة",
              "englishname": "are dishwasher curtains available and in good condition ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["متوفرة و في حالة جيدة "],
                  "englishchoices": ["available and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": [" متسخة", "  في مكان خاطئ", "متهالكة", "لا توجد"],
                  "englishchoices": ["missed", "dirty", "badly worn", "wrong position"],
                  "score": 0
                },
                { "name": "na" }
              ]

            },
            {
              "arabicname": "are machine doors clean and work properly",
              "englishname": "هل ابواب الماكينة نظيفة و تعمل بشكل جيد",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["نظيفة و تعمل بشكل جيد"],
                  "englishchoices": ["work properly  and clean"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["خارجة عن العمل ", "تستخدم بشكل خاطئ ", "متهالكة"],
                  "englishchoices": ["out of alignment", " broken lineage", " abuse"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل الماكينة من الداخل نظيفة وفى حالة جيدة ",
              "englishname": "is machine interior clean and in good condition ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["نظيفة وفى حالة جيدة"],
                  "englishchoices": ["clean and in good condition "],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["بها تراكمات من الاملاح ", ", متسخة", "تحتاج الى صيانة"],
                  "englishchoices": ["scale build up", " dirty", " need maintenance"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل انابيب صرف زائد الماء بالتنكات تعمل بكفاءة و في حالة جيدة ",
              "englishname": "are tank over flow properly work and in good condition  ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["تعمل بكفاءة و في حالة جيدة "],
                  "englishchoices": ["work properly  and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["الجزء العلوى منها مفقود ", "بها انسداد"],
                  "englishchoices": ["cover pan missing", "blocked"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل حاجز البقايا نظيف و في حالة جيدة",
              "englishname": "are scraping tray clean and in good condition ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["نظيف و في حالة جيدة "],
                  "englishchoices": ["clean and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["متسخ ", " لا يوجد ", ", متهالك وبها انحناءات "],
                  "englishchoices": ["dirty", "missing", "bent"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل سلة جمع البقايا بالماكينة نظيفة وفى حالة جيدة ",
              "englishname": "are scraping basket clean and in good condition ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["نظيف و في حالة جيدة "],
                  "englishchoices": ["clean and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["متسخة ", " لا يوجد ", ", متهالكة "],
                  "englishchoices": ["dirty", "missing", "damaged"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل سخان المياه في حالة جيدة",
              "englishname": "is heating coil in good condition ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["في حالة جيدة"],
                  "englishchoices": ["in good condition "],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["عليه تراكمات من الاملاح ", "يحتاج الى صيانة", ", لا يعمل و متهالك"],
                  "englishchoices": ["scale build up", " need maintenance", " not working"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل الرشاشات تعمل و في حالة جيده في مرحلة الاولي من الماكينة",
              "englishname": "are spraying arms in pre-wash tank work appropriately and in good condition",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["تعمل بشكل جيد و في حالة جيدة"],
                  "englishchoices": ["appropriate and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["متسخة للغاية ", " بها انسداد ", " متهالكة ", " لا تعمل ", " لا تدور ", " تحتاج الى صيانة "],
                  "englishchoices": ["very dirty", "blocked", "bent over", "not working", "not rotating", "need maintenance"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل درجة حرارة المرحلة الاولي 45 درجة ",
              "englishname": "are temperature of pre-washing appropriate 45°c  ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["ملائمة"],
                  "englishchoices": ["appropriate"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["اعلي من 55 درجة", "اقل من 45 درجة"],
                  "englishchoices": ["temperature ˃ 55", "temperature < 45"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل الرشاشات تعمل و في حالة جيده في مرحلة الثانية من الماكينة",
              "englishname": "are spraying arms in washing tank work appropriately and in good condition ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["تعمل بشكل ملائم و في حالة جيدة"],
                  "englishchoices": ["appropriate and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["متسخة للغاية ", " بها انسداد ", " متهالكة ", " لا تعمل ", " لا تدور ", " تحتاج الى صيانة "],
                  "englishchoices": ["very dirty", "blocked", "bent over", "not working", "not rotating", "need maintenance"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل درجة حرارة المرحلة الثانية (غسيل) 65 درجة ",
              "englishname": "are temperature of wash appropriate 65°c ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["ملائمة"],
                  "englishchoices": ["appropriate"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["درجة الحرارة اعلي من 65 درجة", "درجة الحرارة اقل  من 55 درجة"],
                  "englishchoices": ["temperature ˃ 65", "temperature < 55"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل مياه الغسيل بالتنكات نظيفة ومناسبة",
              "englishname": "is washing water clean and appropriate",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["نظيفة ومناسبة"],
                  "englishchoices": ["clean and appropriate"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["متسخة", "بها فقاعات بشكل كبير"],
                  "englishchoices": ["dirty water", "contain excessive foam "],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل الرشاشات في المرحلة الثالثة (مرحلة الشطف النهائي) تعمل بشكل ملائم و في حالة جيدة",
              "englishname": "are spraying arms in final rinse work appropriately and in good condition",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["تعمل بشكل ملائم و في حالة جيدة"],
                  "englishchoices": ["appropriate and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["متسخة للغاية ", " بها انسداد ", " متهالكة ", " لا تعمل ", " لا تدور ", " تحتاج الى صيانة "],
                  "englishchoices": ["very dirty", "blocked", "bent over", "not working", "not rotating", "need maintenance"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل درجة حرارة المرحلة الثالثة (شطف نهائي) 82 درجة او اكثر",
              "englishname": "are temperature of final rinse appropriate 82°c or more",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["ملائمة"],
                  "englishchoices": ["appropriate"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["درجة الحرارة عاليه جدا ", "درجة الحرارة اقل من 82 درجة"],
                  "englishchoices": ["temperature too high", "temperature <  82°c  "],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل ضغط المياه في ماكينة غسيل الاطباق مناسب ",
              "englishname": "Is flow in dishwashing machine appropriate ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["لضغط مناسب في كافة اجزاء الماكينة"],
                  "englishchoices": ["flow is good at all "],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["الضغط ضعيف في مرحلة الشطف النهائي ", " الضغط ضعيف في مرحلة الغسيل ", " الضغط ضعيف في مرحلة الشطف الاولي ", " الضغط عالي في مرحلة الشطف النهائي ", " الضغط عالي في مرحلة الغسيل ", " الضغط عالي في مرحلة الشطف الاولي "],
                  "englishchoices": ["flow too high in final rinse", " flow too high in washing step ", " flow too high in pre-rinse", " flow too low in final rinse ", " flow too low in washing ", " flow too low pre-rinse"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل عداد (شاشة اظهار) درجة حرارة الماكينة تعمل و بدقة  ",
              "englishname": "are dishwasher temperature gauges accurate and properly work",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["تعمل بشكل دقيق"],
                  "englishchoices": ["accurate working"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["لا تعمل ", "غير دقيقة"],
                  "englishchoices": ["Broken", "Inaccurate readings"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل مضخة الكيماويات تعمل بشكل ملائم و في حالة جيدة  ",
              "englishname": "is dosing pump working appropriately and in good condition",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["تعمل بشكل ملائم و في حالة جيدة"],
                  "englishchoices": ["appropriate and in good condition"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["مغلقة اثناء عمل الماكينة", " لا تعمل ", " تحتاج الى صيانة و معايرة"],
                  "englishchoices": ["shut off", "broken", "need maintenance and adjustment "],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل انابيب مضخة الكيماويات و الكيماويات في حالة جيدة و متوفرة ",
              "englishname": "are tubes of dosing pump and chemical container in good condition and available ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["جيدة والكيماويات متوفرة"],
                  "englishchoices": ["appropriate and available "],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["الانابيب متهالكة ", " الانابيب بها انحناءات و انضغاطات ", " الانابيب لا تعمل او بها انسداد  ", " لا يوجد كيماويات ", " يتم استخدام الكيماويات الغير صحيحة "],
                  "englishchoices": ["Chemical lines cracked/worn", "Squeeze tube(s) worns", "Flush manifold defective", "Container(s) empty", "Incorrect product being used"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل الحوض الثلاثي متوفر ويتم تطهير و غسل المعدات و الاواني به ",
              "englishname": "are three sink pot washing process carried out appropriately ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["غسيل و تطهير الاواني يتم بشكل ملائم "],
                  "englishchoices": ["proper pot washing"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["لا يتم الغسيل بشكل ملائم  ", "لا تتم مرحلة التطهير", " لا يتم النقع ", " لا يوجد حوض ثلاثي "],
                  "englishchoices": ["improper washing", "improper sanitizing", "improper soaking", "no three sink present"],
                  "score": 0
                },
                { "name": "na" }
              ]
            }
          ]
        },

        {
          "name": "Unloading, handling and storage",
          "questions": [
            {
              "arabicname": "هل الاطباق تخرج من الماكينة نظيفة و جافة  ",
              "englishname": "are wares delivered clean and dry from machine ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["الاطباق تخرج نظيفة و جافة"],
                  "englishchoices": ["ware dry and clean "],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["تخرج بها علامات", "تخرج متسخة", "تخرج غير جافة", "تخرج بها رائحة سيئة"],
                  "englishchoices": ["stained", "soiled", "misty", "off odor"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل يسمح للأطباق بفترة مناسبة وكافية حتي تجف",
              "englishname": "are adequate and sufficient time allowed to dry",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": [" الاطباق يسمح لها بوقت كافي لتجف"],
                  "englishchoices": ["adequate time allowed"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["لا تترك لتجف", "يستخدم فوط لتجفيفها"],
                  "englishchoices": ["not allowed to dry", "dry using towels"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل الاطباق تتداول بشكل مناسب و ملائم",
              "englishname": "are wares handling carried out properly",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": [" الاطباق يتم التعامل معها و تداولها بشكل صحيح"],
                  "englishchoices": ["ware handled properly"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["الاطباق يتم الامساك بها من المنتصف", "الشوك و السكاكين و الملاعق يتم الامساك به من الجزء الخاص بالأكل", "يتم الامساك بالأغراض بيد متسخة", "الاكواب و الفناجين لا يتم تصفيتها", "الاطباق و الاغراض المكسورة و المشروخة لا يتم استبعادها", "الاطباق المتسخة لا يتم اعادة غسلها"],
                  "englishchoices": ["plates handled from the center ", "Cutlery handled from eating end", "handle wares with dirty hand", "cups and bowels not drained off", "chipped and broken wares not removed", "dirty wares not return"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل الاغراض (الاطباق) يتم تخزينها بشكل مناسب و ملائم ",
              "englishname": "are wares stored well and in correct manner  ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["يتم تخزينها بشكل صحيح وملائم"],
                  "englishchoices": ["stored at right place with right manner"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["مخزنة بشكل غير ملائم", "منطقة التخزين غير منظمة وسيئة"],
                  "englishchoices": ["improper stored ", "untidy storage area"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل معدلات الكسر في النطاق الطبيعي",
              "englishname": "is breakage rate within normal range ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["في المعدل الطبيعي"],
                  "englishchoices": ["within normal range "],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": [" اعلى من المعدل الطبيعي "],
                  "englishchoices": ["too high"],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل الاواني يتم تخزينها بشكل مناسب و ملائم",
              "englishname": "are pots and utensils stored in appropriate manner ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["يتم تخزينها بشكل صحيح وملائم"],
                  "englishchoices": ["stored at right place with right manner"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["مخزنة بشكل غير ملائم", "منطقة التخزين غير منظمة وسيئة"],
                  "englishchoices": ["improper stored ", "untidy and dirty storage area"],
                  "score": 0
                },
                { "name": "na" }
              ]
            }]
        },

        {
          "name": "training and record keeping",
          "questions": [
            {
              "arabicname": "هل سجلات درجات الحرارة لغسالة الاطباق متوفرة و يتم الاحتفاظ بها",
              "englishname": "are dishwashing temperature records kept and available ",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["متوفرة"],
                  "englishchoices": ["available"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["لا سجلات", "متلاعب بها"],
                  "englishchoices": ["no records", "fake record "],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل القائم على غسيل الاطباق تم تدريبه و لديه المعرفة الكاملة",
              "englishname": "is dishwasher operator trained well and have knowledge",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["مدرب جيدا"],
                  "englishchoices": ["well trained"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["ليس لدية اى معرفة", "لا يتم التدريب "],
                  "englishchoices": ["have no knowledge", "no training carried out "],
                  "score": 0
                },
                { "name": "na" }
              ]
            },
            {
              "arabicname": "هل اللوحات الارشادية معروضه و في حالة جيدة",
              "englishname": "are wall charts displayed and in good condition",
              "answers": [
                {
                  "name": "c",
                  "arabicchoices": ["ملائمة ومناسبة"],
                  "englishchoices": ["satisfactory"],
                  "score": 5
                },
                {
                  "name": "nc",
                  "arabicchoices": ["لا يوجد او ناقصة", "تحتاج الى تغير", "متهالكة"],
                  "englishchoices": ["missed", "Need replacement", "Badly Worn"],
                  "score": 0
                },
                { "name": "na" }
              ]
            }
          ]
        }
      ]
    }

    this.firestore.collection('modules').doc("RqB7HeIW2jnQpm51azDH").update(obj2)
      .then(value => {
        this.LoaderServiceService.hide();

      })
      .catch(error => {
        this.LoaderServiceService.hide();

        console.log('Something went wrong: ', error);
      });
  }
  //   listofclients():AngularFirestoreCollection<clients>{
  //     return this.firestore.collection('clients');

  // }

  getfilestodownload(clientid) {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('files', ref => ref.where('clientid', '==', clientid)).get().subscribe((client) => {
        var data = client.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  public listofclients(): AngularFirestoreCollection<clients> {
    // this.LoaderServiceService.show();
    return this.firestore.collection('clients');
    // this.LoaderServiceService.hide();

    // const categoryDocRef = this.firestore.doc('clients/test');
    // return this.firestore.collection("clients").where("parent", "==", categoryDocRef)
    // .get()
    // .then(function(querySnapshot) {
    //    querySnapshot.forEach(function(doc) {
    //       console.log(doc.id, " => ", doc.data() as any);
    //    });
    // })
    // .catch(function(error) {
    //    console.log("Error getting documents: ", error);
    // });


  }



  // public listofclients(): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     this.firestore.collection('clients').get().subscribe((id: any) => {
  //       let data = id.docs.map(element => {
  //         let item = element.data() as any;
  //         item.id = element.id;
  //         return item;
  //       });
  //       resolve(data);
  //     }, error => {
  //       reject(error);
  //     });
  //   });
  // }


  // this.generalservice
  //   .listofclients()
  //   .snapshotChanges()
  //   .pipe(
  //     map((changes) =>
  //       changes.map((c) => ({
  //         id: c.payload.doc.id,
  //         ...c.payload.doc.data() as any,
  //       }))
  //     )
  //   )
  //   .subscribe((data) => {
  //     this.data = data;
  //     console.log(data);
  //     this.dataSource = new MatTableDataSource(this.data);
  //   });

  public getallAssinger(): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('users', ref => ref.where('role', '==', 'assign')).get().subscribe((client) => {
        var data = client.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  public getallAssingerByDepartmentId(ClientId, departmentid): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('users', ref => ref.where('role', '==', 'assign').where('clientid', '==', ClientId).where('departmentid', 'array-contains', departmentid)).get().subscribe((client) => {
        var data = client.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();
      });

    });
  }
  public getAssingerByClientId(ClientId): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('users', ref => ref.where('clientid', '==', ClientId).where('role', '==', 'assign')).get().subscribe((client) => {
        var data = client.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  public getBranchByClientId(clientid: any): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any[]>(async (resolve, reject) => {
      const userInfo = JSON.parse(localStorage.getItem("currentuser"));
      console.log(userInfo, "current user data");
      try {
        this.firestore.collection('branches', ref => ref.where('clientid', '==', clientid)).get().subscribe((client) => {
          var data = client.docs.map(element => {
            var item = element.data() as any;
            item.id = element.id;
            return item;
          })
          if (userInfo.role === "cluster" || userInfo.role === "admin" || userInfo.role === "ceo") {
            resolve(data);
            this.LoaderServiceService.hide();

          }
          else {
            const branchs = [];
            userInfo.branchesid.forEach(id => {
              const matchedObject = data.find(item => item.id === id);
              if (matchedObject) {
                branchs.push(matchedObject);
              }
            });
            resolve(branchs);
            this.LoaderServiceService.hide();

          }
        }, error => {
          reject(error);
          this.LoaderServiceService.hide();

        });

      } catch (error) {
        reject(error);
        this.LoaderServiceService.hide();
      }

    });
  }



  public getAnalysisTaskCompilationMonthlyByDate(moduleId, branchId, month, year): Promise<any> {
    console.log(moduleId, "moduleId Service")
    console.log(branchId, "branchId Service")
    console.log(month, "month Service")
    console.log(year, "year Service")
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('AnalysisTaskCompilationMonthly', ref =>
        ref.where('month', '==', month)
          .where('year', '==', year)
          .where('branchid', '==', branchId)
          .where('moduleid', '==', moduleId)
      ).get().subscribe((client) => {
        var data = client.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        console.log(data)
        resolve(data);

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();
      });

    });
  }
  public UpdateAnalysisTaskCompilationMonthlyByDate(data, id): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('AnalysisTaskCompilationMonthly').doc(id).update(data).then((client) => {
        console.log(data)
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }


  // public getAnalysisTaskCompilationYearlyByDate(moduleId,branchId,year): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     this.firestore.collection('AnalysisTaskCompilationYearly', ref => 
  //     ref.where('year', '==', year)
  //     .where('branchid', '==', branchId)
  //     .where('moduleid', '==', moduleId)
  //     ).get().subscribe((client) => {
  //       var data = client.docs.map(element => {
  //         var item = element.data() as any;
  //         item.id = element.id;
  //         return item;
  //       })
  //       console.log(data)
  //       resolve(data);
  //     }, error => {
  //       reject(error);
  //     });
  //   });
  // }



  getAnalysisTaskCompilationYearlyByDate(moduleId: string, branchId: string, year: number): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('AnalysisTaskCompilationYearly', ref => {
        let query = ref.where('year', '==', year);
        query = query.where('branchid', '==', branchId);
        query = query.where('moduleid', '==', moduleId);
        return query;
      }).get().subscribe({
        next: (querySnapshot) => {
          // Check if querySnapshot is defined and has documents
          if (querySnapshot.empty) {
            resolve([]); // Resolve with an empty array if no documents found
            return;
          }

          // Map over the documents and extract data
          const data = querySnapshot.docs.map(doc => {
            // Ensure that 'doc' has the 'id' and 'data' methods
            const item = doc.data() as any;
            item.id = doc.id; // Attach the document ID to the item
            return item;
          });

          console.log(data);
          resolve(data);

        },
        error: (error) => {
          // Handle errors and reject the promise
          console.error('Error fetching data:', error);
          reject(error);
          this.LoaderServiceService.hide();

        }
      });

    });
  }




  public UpdateAnalysisTaskCompilationYearlyByDate(data, id): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('AnalysisTaskCompilationYearly').doc(id).update(data).then((client) => {
        console.log(data)
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }

  public getallDepartment(): Promise<any> {
    this.LoaderServiceService.hide();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('department').get().subscribe((department) => {
        var data = department.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }


  async addDepartment(Department) {
    this.LoaderServiceService.show();
  
    return new Promise<any>((resolve, reject) => {
      // Add department to Firestore
      this.firestore.collection('department').add(Department).then(docRef => {
        // Add the `id` field to the newly created document
        docRef.update({ id: docRef.id }).then(() => {
          resolve(docRef);
          this.LoaderServiceService.hide();
        }).catch(error => {
          reject(error);
          this.LoaderServiceService.hide();
        });
      }, error => {
        reject(error);
        this.LoaderServiceService.hide();
      });
    });
  }

  
  // async addDepartment(Department) {
  //   this.LoaderServiceService.show();

  //   return new Promise<any>((resolve, reject) => {
  //     this.firestore.collection('department').add(Department).then(res => {

  //       resolve(res);
  //       this.LoaderServiceService.hide();
  //     }, error => {
  //       reject(error);
  //       this.LoaderServiceService.hide();
  //     });


  //   });
  // }
  async addDocument(Document) {

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('files').add(Document).then(res => {
        resolve(res);
      }, error => {
        reject(error);

      });


    });
  }
  async addTestLab(Test) {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('testslab').add(Test).then(res => {
        resolve(res);
        this.LoaderServiceService.hide();
      }, error => {
        reject(error);
        this.LoaderServiceService.hide();
      });


    });
  }


  async getAllTestsMonths(filterobj: any, dates: { month: number, year: number }[]): Promise<any> {
    this.LoaderServiceService.show();

    console.log("Filter Object:", filterobj);
    console.log("Dates:", dates);
    let promises;
    if (filterobj.TestId) {
      console.log("enter test id");
      promises = dates.map(date => {
        return this.firestore.collection('testslab', ref => {
          let query = ref.where('ClientId', '==', filterobj.ClientId)
            .where('Branch', '==', filterobj.Branch)
            .where('ItemId', '==', filterobj.ItemId)
            .where('CategoryId', '==', filterobj.CategoryId)
            .where('TestId', '==', filterobj.TestId)
            .where('Month', '==', date.month)
            .where('Year', '==', date.year)
          return query;
        }).get().toPromise();
      });
    }
    else {
      promises = dates.map(date => {
        return this.firestore.collection('testslab', ref => {
          let query = ref.where('ClientId', '==', filterobj.ClientId)
            .where('Branch', '==', filterobj.Branch)
            .where('ItemId', '==', filterobj.ItemId)
            .where('CategoryId', '==', filterobj.CategoryId)
            .where('Month', '==', date.month)
            .where('Year', '==', date.year)
          return query;
        }).get().toPromise();
      });

    }

    try {
      const results = await Promise.all(promises);
      console.log(results);
      const data = results.map(res => {
        return res.docs.map(element => {
          var item = element.data();
          // item.id = element.id;
          return item;
        });
      });
      console.log(data);
      this.LoaderServiceService.hide();

      return data;
    } catch (error) {
      this.LoaderServiceService.hide();
      console.error('Error fetching or processing data:', error);
      throw error;
    }


  }
  public getallDocument(): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('files').get().subscribe((client) => {
        var data = client.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }

  public getallDocumentForClientById(clientId, moudelIds): Promise<any[]> {
    console.log(moudelIds)
    this.LoaderServiceService.show();

    return new Promise<any[]>((resolve, reject) => {
      const results: any[] = [];

      // Define Firestore collection reference with the desired collection name
      const collectionRef: AngularFirestoreCollection<any> = this.firestore.collection('files');

      // Query 1: Retrieve documents where 'private' is false
      const query1 = collectionRef.ref.where('private', '==', 'false').where('moduleId', 'in', moudelIds).get();

      // Query 2: Retrieve documents where 'private' is true and 'clientId' matches
      const query2 = collectionRef.ref.where('private', '==', 'true').where('clientid', '==', clientId).where('moduleId', 'in', moudelIds).get();

      // Execute both queries concurrently using Promise.all
      Promise.all([query1, query2]).then((snapshots) => {
        const data1 = snapshots[0].docs.map((doc) => {
          const item = doc.data() as any;
          item.id = doc.id;
          return item;
        });

        // Process results from query 2 (where 'private' is true and 'clientId' matches)
        const data2 = snapshots[1].docs.map((doc) => {
          const item = doc.data() as any;
          item.id = doc.id;
          return item;
        });

        // Merge both sets of results into a single array
        results.push(...data1, ...data2);

        // Resolve the promise with the merged results
        resolve(results);
        this.LoaderServiceService.hide();

        // Process results similar to the previous example...
      }).catch((error) => {
        reject(error);
        this.LoaderServiceService.hide();
        // Handle error
      });

    });
  }

  UpdateDepartment(Department, id) {
    console.log(id);
    console.log(Department);
    this.LoaderServiceService.show();

    return this.firestore.collection('department').doc(id).update(Department)
      .then(value => {
        this.LoaderServiceService.hide();


      })
      .catch(error => {
        this.LoaderServiceService.hide();

        console.log('Something went wrong: ', error);
      });

  }


  async DeleteDepartment(id) {
    this.LoaderServiceService.show();

    this.firestore.collection("department").doc(id).delete().then(value => {
      console.log(value)
      this.LoaderServiceService.hide();

    })
      .catch(error => {
        this.LoaderServiceService.hide();

        console.log('Something went wrong: ', error);
      });
  }
  public getallClient(): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('clients').get().subscribe((client) => {
        var data = client.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }

  public getallAssign(id): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('users', ref => ref.where('clientid', '==', id).where('role', "==", 'assign')).get().subscribe((assign) => {
        var data = assign.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();
      }, error => {
        reject(error);
        this.LoaderServiceService.hide();
      });

    });
  }

  DelUser(item) {
    this.currentuser = JSON.parse(localStorage.getItem("currentuser"));
    
    return this.qutaCountDelUsers(this.currentuser.clientid).pipe(
      switchMap(success => {
        if (success) {
          this.LoaderServiceService.show();
          return this.firestore.collection("users").doc(item).delete().then(() => {
            this.LoaderServiceService.hide();
            return true; // Return true on success
          }).catch(error => {
            this.LoaderServiceService.hide();
            console.log('Something went wrong: ', error);
            return false; // Return false on error
          });
        } else {
          return of(false); // Return false if quota check fails
        }
      })
    );
  }
  
  // getclientbyid(id) {
  //   this.moduleref = []
  //   this.LoaderServiceService.show();

  //   return new Promise<any>((resolve, reject) => {
  //     this.firestore.collection('clients', ref => ref.where('id', '==', id)).get().subscribe(client => {
  //       var clients = client.docs.map(doc => {
  //         var item = doc.data() as any;
  //         console.log(item)
  //         localStorage.setItem('ClientData',JSON.stringify(item))
  //         //   this.moduleref=[];
  //         item.moudlerf.forEach((element, index) => {
  //           element.id.get().then(doc => {
  //             console.log(doc.data() as any, "doc.data() as any")
  //             if (doc.data() as any) {
  //               this.doc = doc.data() as any;
  //               this.doc["id"] = doc.id
  //               this.moduleref.push(this.doc);
  //               //  this.moduleref['id'] = doc.id;
  //             }
  //           });
  //         });
  //         item.moudlerf = this.moduleref;
  //         item.id = doc.id;
  //         return item;
  //       });

  //       resolve(clients);
  //       this.LoaderServiceService.hide();

  //     }, error => {
  //       reject(error);
  //       this.LoaderServiceService.hide();

  //     }
  //     );
  //   });
  // }
  addmodule() {
    // const data = 
    //     {
    //         "iconios": "ios-american-football",
    //         "private": false,
    //         "iconmd": "md-american-football",
    //         "categories": [
    //             {
    //                 "name": "infrastructure",
    //                 "questions": [
    //                     {
    //                         "answers": [
    //                             {
    //                                 "arabicchoices": [
    //                                     "نظيفة و في حالة جيدة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "clean and in good condition"
    //                                 ],
    //                                 "name": "c",
    //                                 "score": 5
    //                             },
    //                             {
    //                                 "score": 0,
    //                                 "englishchoices": [
    //                                     "english choice",
    //                                     "english choice"
    //                                 ],
    //                                 "name": "nc",
    //                                 "arabicchoices": [
    //                                     "dirty",
    //                                     "poor condition"
    //                                 ]
    //                             },
    //                             {
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "name": "m",
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ],
    //                                 "score": 2
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "englishname": "are walls and doors clean and in good condition ",
    //                         "departmentid": "CS2wkskf35SD4E2TRDvb",
    //                         "arabicname": "هل الحوائط و الارضيات نظيفة وفي حالة جيدة ",
    //                         "id": "0ae5a6e9-1fca-4e4f-a4b6-c18b574f27d7"
    //                     },
    //                     {
    //                         "id": "43a1be68-83c3-4c36-9426-181388aa3b99",
    //                         "englishname": "are floors clean and in good condition ",
    //                         "departmentid": "uWUR4P1pSF4BXJvhhozj",
    //                         "arabicname": "هل الارضيات نظيفة و في حالة جيدة ",
    //                         "answers": [
    //                             {
    //                                 "name": "c",
    //                                 "arabicchoices": [
    //                                     "نظيفة و في حالة جيدة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "clean and in good condition"
    //                                 ],
    //                                 "score": 5
    //                             },
    //                             {
    //                                 "score": 0,
    //                                 "arabicchoices": [
    //                                     "متسخة",
    //                                     "في حالة سيئة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "dirty",
    //                                     "poor condition"
    //                                 ],
    //                                 "name": "nc"
    //                             },
    //                             {
    //                                 "name": "m",
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "score": 2,
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ]
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         "departmentid": "CS2wkskf35SD4E2TRDvb",
    //                         "answers": [
    //                             {
    //                                 "arabicchoices": [
    //                                     "نظيفة و في حالة جيدة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "clean and in good condition"
    //                                 ],
    //                                 "score": 5,
    //                                 "name": "c"
    //                             },
    //                             {
    //                                 "score": 0,
    //                                 "name": "nc",
    //                                 "arabicchoices": [
    //                                     "متسخة",
    //                                     "في حالة سيئة",
    //                                     "يوجد تسريب من السقف",
    //                                     "قشور من السقف"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "dirty",
    //                                     "poor condition",
    //                                     "evidence of leakage",
    //                                     "paint flecks"
    //                                 ]
    //                             },
    //                             {
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "name": "m",
    //                                 "score": 2
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "englishname": "Is ceiling clean and in good condition ",
    //                         "arabicname": "هل الاسقف جيدة و في حالة جيدة  ",
    //                         "id": "dca88446-74f9-425f-a12f-e254aa70de30"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "questions": [
    //                     {
    //                         "departmentid": "CS2wkskf35SD4E2TRDvb",
    //                         "englishname": "is hand-scarping process carried out properly ",
    //                         "id": "419fc6c5-b53d-49cb-a06a-7f61b96a3b84",
    //                         "answers": [
    //                             {
    //                                 "name": "c",
    //                                 "score": 5,
    //                                 "englishchoices": [
    //                                     "proper hand-scraping"
    //                                 ],
    //                                 "arabicchoices": [
    //                                     "تتم بشكل مناسب وصحيح"
    //                                 ]
    //                             },
    //                             {
    //                                 "arabicchoices": [
    //                                     "لا تتم بشكل مناسب ",
    //                                     "لا تتم اطلاقا"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "improper hand scraping",
    //                                     "no hand scraping carried out"
    //                                 ],
    //                                 "score": 0,
    //                                 "name": "nc"
    //                             },
    //                             {
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "score": 2,
    //                                 "name": "m",
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ]
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "arabicname": "هل عملية ازالة البقايا تتم بشكل مناسب "
    //                     },
    //                     {
    //                         "arabicname": "هل يتم استخدام ادوات ازالة البقايا تسبب تجريح او خشنة ",
    //                         "englishname": "are scouring pads or other abrasive items used for scraping ",
    //                         "id": "a9636410-ad55-4612-ab9b-73e3d41f6232",
    //                         "answers": [
    //                             {
    //                                 "name": "c",
    //                                 "score": 5,
    //                                 "arabicchoices": [
    //                                     "تستخدم ادوات مناسبة غير خشنة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "non-abrasive pads used"
    //                                 ]
    //                             },
    //                             {
    //                                 "name": "m",
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ],
    //                                 "score": 2,
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ]
    //                             },
    //                             {
    //                                 "englishchoices": [
    //                                     "scouring pads or other abrasive items used"
    //                                 ],
    //                                 "name": "nc",
    //                                 "arabicchoices": [
    //                                     "تستخدم ادوات خشنة"
    //                                 ],
    //                                 "score": 0
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "departmentid": "uWUR4P1pSF4BXJvhhozj"
    //                     }
    //                 ],
    //                 "name": "preparatory process"
    //             },
    //             {
    //                 "name": "infrastructure 222",
    //                 "questions": [
    //                     {
    //                         "answers": [
    //                             {
    //                                 "arabicchoices": [
    //                                     "نظيفة و في حالة جيدة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "clean and in good condition"
    //                                 ],
    //                                 "name": "c",
    //                                 "score": 5
    //                             },
    //                             {
    //                                 "score": 0,
    //                                 "englishchoices": [
    //                                     "english choice",
    //                                     "english choice"
    //                                 ],
    //                                 "name": "nc",
    //                                 "arabicchoices": [
    //                                     "dirty",
    //                                     "poor condition"
    //                                 ]
    //                             },
    //                             {
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "name": "m",
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ],
    //                                 "score": 2
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "englishname": "are walls and doors clean and in good condition ",
    //                         "departmentid": "CS2wkskf35SD4E2TRDvb",
    //                         "arabicname": "هل الحوائط و الارضيات نظيفة وفي حالة جيدة ",
    //                         "id": "0ae5a6e9-1fca-4e4f-a4b6-c18b574ew7d7"
    //                     },
    //                     {
    //                         "id": "43a1be68-83c3-4c36-9426-181388dq3b99",
    //                         "englishname": "are floors clean and in good condition ",
    //                         "departmentid": "uWUR4P1pSF4BXJvhhozj",
    //                         "arabicname": "هل الارضيات نظيفة و في حالة جيدة ",
    //                         "answers": [
    //                             {
    //                                 "name": "c",
    //                                 "arabicchoices": [
    //                                     "نظيفة و في حالة جيدة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "clean and in good condition"
    //                                 ],
    //                                 "score": 5
    //                             },
    //                             {
    //                                 "score": 0,
    //                                 "arabicchoices": [
    //                                     "متسخة",
    //                                     "في حالة سيئة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "dirty",
    //                                     "poor condition"
    //                                 ],
    //                                 "name": "nc"
    //                             },
    //                             {
    //                                 "name": "m",
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "score": 2,
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ]
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         "departmentid": "CS2wkskf35SD4E2TRDvb",
    //                         "answers": [
    //                             {
    //                                 "arabicchoices": [
    //                                     "نظيفة و في حالة جيدة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "clean and in good condition"
    //                                 ],
    //                                 "score": 5,
    //                                 "name": "c"
    //                             },
    //                             {
    //                                 "score": 0,
    //                                 "name": "nc",
    //                                 "arabicchoices": [
    //                                     "متسخة",
    //                                     "في حالة سيئة",
    //                                     "يوجد تسريب من السقف",
    //                                     "قشور من السقف"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "dirty",
    //                                     "poor condition",
    //                                     "evidence of leakage",
    //                                     "paint flecks"
    //                                 ]
    //                             },
    //                             {
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "name": "m",
    //                                 "score": 2
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "englishname": "Is ceiling clean and in good condition ",
    //                         "arabicname": "هل الاسقف جيدة و في حالة جيدة  ",
    //                         "id": "dca88446-74f9-425f-a12f-easae4aa70de30"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "questions": [
    //                     {
    //                         "departmentid": "CS2wkskf35SD4E2TRDvb",
    //                         "englishname": "is hand-scarping process carried out properly ",
    //                         "id": "419fc6c5-b53d-49cb-a06a-7221b96a33b84",
    //                         "answers": [
    //                             {
    //                                 "name": "c",
    //                                 "score": 5,
    //                                 "englishchoices": [
    //                                     "proper hand-scraping"
    //                                 ],
    //                                 "arabicchoices": [
    //                                     "تتم بشكل مناسب وصحيح"
    //                                 ]
    //                             },
    //                             {
    //                                 "arabicchoices": [
    //                                     "لا تتم بشكل مناسب ",
    //                                     "لا تتم اطلاقا"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "improper hand scraping",
    //                                     "no hand scraping carried out"
    //                                 ],
    //                                 "score": 0,
    //                                 "name": "nc"
    //                             },
    //                             {
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "score": 2,
    //                                 "name": "m",
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ]
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "arabicname": "هل عملية ازالة البقايا تتم بشكل مناسب "
    //                     },
    //                     {
    //                         "arabicname": "هل يتم استخدام ادوات ازالة البقايا تسبب تجريح او خشنة ",
    //                         "englishname": "are scouring pads or other abrasive items used for scraping ",
    //                         "id": "a9636410-ad55-4612-ab9b-73e3d41few3132",
    //                         "answers": [
    //                             {
    //                                 "name": "c",
    //                                 "score": 5,
    //                                 "arabicchoices": [
    //                                     "تستخدم ادوات مناسبة غير خشنة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "non-abrasive pads used"
    //                                 ]
    //                             },
    //                             {
    //                                 "name": "m",
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ],
    //                                 "score": 2,
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ]
    //                             },
    //                             {
    //                                 "englishchoices": [
    //                                     "scouring pads or other abrasive items used"
    //                                 ],
    //                                 "name": "nc",
    //                                 "arabicchoices": [
    //                                     "تستخدم ادوات خشنة"
    //                                 ],
    //                                 "score": 0
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "departmentid": "uWUR4P1pSF4BXJvhhozj"
    //                     }
    //                 ],
    //                 "name": "preparatory process 22"
    //             },
    //             {
    //                 "name": "infrastructure 222",
    //                 "questions": [
    //                     {
    //                         "answers": [
    //                             {
    //                                 "arabicchoices": [
    //                                     "نظيفة و في حالة جيدة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "clean and in good condition"
    //                                 ],
    //                                 "name": "c",
    //                                 "score": 5
    //                             },
    //                             {
    //                                 "score": 0,
    //                                 "englishchoices": [
    //                                     "english choice",
    //                                     "english choice"
    //                                 ],
    //                                 "name": "nc",
    //                                 "arabicchoices": [
    //                                     "dirty",
    //                                     "poor condition"
    //                                 ]
    //                             },
    //                             {
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "name": "m",
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ],
    //                                 "score": 2
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "englishname": "are walls and doors clean and in good condition ",
    //                         "departmentid": "CS2wkskf35SD4E2TRDvb",
    //                         "arabicname": "هل الحوائط و الارضيات نظيفة وفي حالة جيدة ",
    //                         "id": "0ae5a6e9-1fca-4e4f-a4b6-c9868b574ew7d7"
    //                     },
    //                     {
    //                         "id": "43a1be68-83c3-4c36-9426-1813ijldq3b99",
    //                         "englishname": "are floors clean and in good condition ",
    //                         "departmentid": "uWUR4P1pSF4BXJvhhozj",
    //                         "arabicname": "هل الارضيات نظيفة و في حالة جيدة ",
    //                         "answers": [
    //                             {
    //                                 "name": "c",
    //                                 "arabicchoices": [
    //                                     "نظيفة و في حالة جيدة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "clean and in good condition"
    //                                 ],
    //                                 "score": 5
    //                             },
    //                             {
    //                                 "score": 0,
    //                                 "arabicchoices": [
    //                                     "متسخة",
    //                                     "في حالة سيئة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "dirty",
    //                                     "poor condition"
    //                                 ],
    //                                 "name": "nc"
    //                             },
    //                             {
    //                                 "name": "m",
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "score": 2,
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ]
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         "departmentid": "CS2wkskf35SD4E2TRDvb",
    //                         "answers": [
    //                             {
    //                                 "arabicchoices": [
    //                                     "نظيفة و في حالة جيدة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "clean and in good condition"
    //                                 ],
    //                                 "score": 5,
    //                                 "name": "c"
    //                             },
    //                             {
    //                                 "score": 0,
    //                                 "name": "nc",
    //                                 "arabicchoices": [
    //                                     "متسخة",
    //                                     "في حالة سيئة",
    //                                     "يوجد تسريب من السقف",
    //                                     "قشور من السقف"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "dirty",
    //                                     "poor condition",
    //                                     "evidence of leakage",
    //                                     "paint flecks"
    //                                 ]
    //                             },
    //                             {
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "name": "m",
    //                                 "score": 2
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "englishname": "Is ceiling clean and in good condition ",
    //                         "arabicname": "هل الاسقف جيدة و في حالة جيدة  ",
    //                         "id": "dca88446-74f9-425f-a12f-easa980aa70de30"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "questions": [
    //                     {
    //                         "departmentid": "CS2wkskf35SD4E2TRDvb",
    //                         "englishname": "is hand-scarping process carried out properly ",
    //                         "id": "419fc6c5-8983d-49cb-a06a-7221b96a33b84",
    //                         "answers": [
    //                             {
    //                                 "name": "c",
    //                                 "score": 5,
    //                                 "englishchoices": [
    //                                     "proper hand-scraping"
    //                                 ],
    //                                 "arabicchoices": [
    //                                     "تتم بشكل مناسب وصحيح"
    //                                 ]
    //                             },
    //                             {
    //                                 "arabicchoices": [
    //                                     "لا تتم بشكل مناسب ",
    //                                     "لا تتم اطلاقا"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "improper hand scraping",
    //                                     "no hand scraping carried out"
    //                                 ],
    //                                 "score": 0,
    //                                 "name": "nc"
    //                             },
    //                             {
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ],
    //                                 "score": 2,
    //                                 "name": "m",
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ]
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "arabicname": "هل عملية ازالة البقايا تتم بشكل مناسب "
    //                     },
    //                     {
    //                         "arabicname": "هل يتم استخدام ادوات ازالة البقايا تسبب تجريح او خشنة ",
    //                         "englishname": "are scouring pads or other abrasive items used for scraping ",
    //                         "id": "a9636410-ad55-4612-ab9b-78e3d41few3132",
    //                         "answers": [
    //                             {
    //                                 "name": "c",
    //                                 "score": 5,
    //                                 "arabicchoices": [
    //                                     "تستخدم ادوات مناسبة غير خشنة"
    //                                 ],
    //                                 "englishchoices": [
    //                                     "non-abrasive pads used"
    //                                 ]
    //                             },
    //                             {
    //                                 "name": "m",
    //                                 "arabicchoices": [
    //                                     "ملف الحوادث متواجد ولكن غير كاملة"
    //                                 ],
    //                                 "score": 2,
    //                                 "englishchoices": [
    //                                     "available but not completed"
    //                                 ]
    //                             },
    //                             {
    //                                 "englishchoices": [
    //                                     "scouring pads or other abrasive items used"
    //                                 ],
    //                                 "name": "nc",
    //                                 "arabicchoices": [
    //                                     "تستخدم ادوات خشنة"
    //                                 ],
    //                                 "score": 0
    //                             },
    //                             {
    //                                 "name": "na"
    //                             }
    //                         ],
    //                         "departmentid": "uWUR4P1pSF4BXJvhhozj"
    //                     }
    //                 ],
    //                 "name": "preparatory process 33"
    //             }
    //         ],
    //         "image": "https://firebasestorage.googleapis.com/v0/b/i-monitor-19ba2.appspot.com/o/gs%3A%2F%2Fi-monitor-19ba2.appspot.com%2Fmodule%2Fhealth%20and%20safety.png%2FFood%20Safety.png?alt=media&token=de14d3ee-9692-4b26-98a1-879785a90ffd",
    //         "name": "test ic",
    //     }
    const data =   {
      "iconios": "ios-american-football",
      "private": false,
      "signtureurl": "",
      "active": true,
      "categories": [
        {
          "name": "Documentation",
          "questions": [
            {
              "id": "18952fcd-560e-4efb-88d5-08ed8c718f6e",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Current year Foodborne Illness Notification Procedures are on file and accessible",
              "arabicname": "إجراءات الإبلاغ عن الأمراض المنقولة بالغذاء للسنة الحالية محفوظة ومتاحة.",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "إجراءات الإبلاغ عن الأمراض المنقولة بالغذاء للسنة الحالية محفوظة ومتاحة بسهولة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "Notification Procedures are on file and easily accessible"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "إجراءات الإبلاغ عن الأمراض المنقولة بالغذاء للسنة الحالية غير محدثة",
                    "إجراءات الإبلاغ عن الأمراض المنقولة بالغذاء للسنة الحالية غير مكتملة"
                  ],
                  "englishchoices": [
                    "Notification Procedures are not updated",
                    "Notification Procedures are not completed"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "Notification Procedures are not on file",
                    "Notification Procedures are not accessible."
                  ],
                  "arabicchoices": [
                    "إجراءات الإبلاغ عن الأمراض المنقولة بالغذاء للسنة الحالية غير محفوظة",
                    "إجراءات الإبلاغ عن الأمراض المنقولة بالغذاء للسنة الحالية غير  متاحة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "2900f0de-4bdd-4467-a3d7-37916257fab5",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Proof of Food Safety training for all culinary food handling associates is current and available for review",
              "arabicname": "هل دليل تدريب سلامة الغذاء لجميع العاملين في إعداد الطعام حديث ومتاح للمراجعة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "دليل تدريب سلامة الغذاء حديث ومتوفر للمراجعة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "proof of current Food Safety training is available for review."
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "دليل تدريب سلامة الغذاء غير متوفر  في بعض الاماكن",
                    "دليل تدريب سلامة الغذاء قديم في بعض الاقسام"
                  ],
                  "englishchoices": [
                    "proof of Food Safety training is not available in some area",
                    "proof of Food Safety training is outdated for some departments"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "proof of Food Safety training is not available",
                    "proof of Food Safety training is outdated"
                  ],
                  "arabicchoices": [
                    "دليل تدريب سلامة الغذاء غير متوفر ",
                    "دليل تدريب سلامة الغذاء قديم"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "3f5b39c7-cf6e-4182-b037-13072e991c0d",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "eGm3Ti8PAthhtE1oL8VI",
              "score": 0,
              "englishname": "The appropriate managers are Food Safety Certified through an approved program",
              "arabicname": "هل المديرون المناسبون حاصلون على شهادة سلامة الغذاء من خلال برنامج معتمد؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "المديرون المناسبون حاصلون على شهادات معتمدة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "Appropriate managers are certified"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "إجراءات الإبلاغ عن الأمراض المنقولة بالغذاء للسنة الحالية غير محدثة",
                    "إجراءات الإبلاغ عن الأمراض المنقولة بالغذاء للسنة الحالية غير مكتملة"
                  ],
                  "englishchoices": [
                    "Some managers are certified in Food Safety",
                    "بعض المديرين حاصلين على شهادة سلامة الغذاء"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "All managers are not certified in Food Safety"
                  ],
                  "arabicchoices": [
                    "جميع المديرين غير حاصلين على شهادة سلامة الغذاء"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "a48f400c-00d6-4120-b3a6-716ba0c14003",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "2coJEjsr62gcsempqetU",
              "score": 0,
              "englishname": "A Pest Prevention program must be adopted and effective",
              "arabicname": "هل تم اعتماد وتنفيذ برنامج مكافحة الآفات بشكل فعال؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "تم اعتماد وتنفيذ برنامج مكافحة الآفات بشكل فعال كما هو مطلوب"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "effective Pest Prevention program is in place and functioning as required"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "برنامج مكافحة الآفات غير موجود في بعض الاماكن",
                    "برنامج مكافحة الآفات غير فعال في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "Pest Prevention program is not in place in some area",
                    "Pest Prevention program is not effective in some area"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "Pest Prevention program is not in place",
                    "Pest Prevention program is not effective"
                  ],
                  "arabicchoices": [
                    "برنامج مكافحة الآفات غير متوفر ",
                    "برنامج مكافحة الآفات غير فعال"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "eeb63beb-7453-46ab-81aa-87f5dc87cc1e",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Refrigerator and freezer temperature logs (HACCP Log A-3) are completed and on file for 90 days",
              "arabicname": "هل يتم إكمال سجلات درجات حرارة الثلاجات والمجمدات (سجل HACCP A-3) والاحتفاظ بها لمدة 90 يوماً؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "سجلات درجات حرارة الثلاجات والمجمدات مكتمل"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "refrigerator and freezer temperature logs are completed"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "سجلات درجات حرارة الثلاجات مفقودة لبعض الفترات",
                    "سجلات درجات حرارة الفريزرات مفقودة لبعض الفترات"
                  ],
                  "englishchoices": [
                    "Refrigerator temperature logs are missing for some periods",
                    "Freezer temperature logs are missing for some periods"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "Refrigerator temperature logs are not completed",
                    "Freezer temperature logs are not completed"
                  ],
                  "arabicchoices": [
                    "سجلات درجات حرارة الثلاجات غير مكتملة ",
                    "سجلات درجات حرارة المجمدا غير مكتملة "
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "201440da-e834-4370-be00-595e7697ff2e",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Temperature Logs (HACCP Log A-1) for cooking, holding, and reheating food items are completed and on file for the past 90 days",
              "arabicname": "هل يتم إكمال سجلات درجات الحرارة (سجل HACCP A-1) للطهي والحفظ وإعادة التسخين والاحتفاظ بها لمدة 90 يوما",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "سجلات درجات حرارة مكتملة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "temperature logs are completed"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "سجلات درجات الحرارة غير مكتملة فى بعض الاماكن",
                    "سجلات درجات الحرارة غير متوفرة في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "temperature logs are incomplete in some places",
                    "temperature logs are unavailable in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "temperature logs are incomplete",
                    "temperature logs are unavailable"
                  ],
                  "arabicchoices": [
                    "سجلات درجات الحرارة غير مكتملة ",
                    "سجلات درجات الحرارة غير متوفرة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "55e76103-5ceb-4c18-be2d-414dce82e95a",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Food Cooling Logs (HACCP Log A-2) are completed and on file for the past 90 days",
              "arabicname": "هل يتم إكمال سجلات تبريد الطعام (سجل HACCP A-2) والاحتفاظ بها لمدة 90 يوماً؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم إكمال سجلات تبريد الطعام والاحتفاظ بها لمدة 90 يوماً"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "food cooling logs are completed and on file for the past 90 days"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "سجلات تبريد الطعام غير مكتملة فى بعض الاماكن",
                    "سجلات تبريد الطعام مفقودة في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "food cooling logs are incompleted in some places",
                    "food cooling logs are missing in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "food cooling logs are incompleted",
                    "food cooling logs are missing"
                  ],
                  "arabicchoices": [
                    "سجلات تبريد الطعام غير مكتملة",
                    "سجلات تبريد الطعام مفقودة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "c7984785-d97c-4fee-a466-fa77d8db0baa",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "2coJEjsr62gcsempqetU",
              "score": 0,
              "englishname": "Work station Cleaning Schedules must be completed and on file for 90 days",
              "arabicname": "هل يتم إكمال جداول تنظيف محطات العمل والاحتفاظ بها لمدة 90 يوماً؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "تم إكمال جداول تنظيف محطات العمل والاحتفاظ بها"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "work station cleaning schedules are completed and available"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "جداول تنظيف محطات العمل غير مكتملة فى بعض الاماكن",
                    "جداول تنظيف محطات العمل مفقودة في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "cleaning schedules are not completed in some places",
                    "cleaning schedules are missing in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "cleaning schedules are not completed",
                    "cleaning schedules are missing"
                  ],
                  "arabicchoices": [
                    "جداول تنظيف محطات العمل غير مكتملة ",
                    "جداول تنظيف محطات العمل مفقودة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "6770c0e4-f09c-407c-abbc-7ca2cbae2876",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Culinary managers are properly trained in food allergy awareness and know how to avoid cross-contact during food preparation and service",
              "arabicname": "هل يتم تدريب مديري الطهي بشكل صحيح على الوعي بالحساسية الغذائية ويعرفون كيفية تجنب التلامس المتبادل أثناء إعداد وتقديم الطعام؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم تدريب مديري الطهي على الوعي بالحساسية الغذائية "
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "culinary managers are trained in food allergy awareness"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "يفتقر مديري الطهي إلى التدريب الكافي على الوعي بالحساسية الغذائية في بعض الاقسام"
                  ],
                  "englishchoices": [
                    "culinary managers lack proper training in food allergy awareness in some departments"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "culinary managers lack proper training in food allergy awareness"
                  ],
                  "arabicchoices": [
                    "يفتقر مديري الطهي إلى التدريب الكافي على الوعي بالحساسية الغذائية"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            }
          ],
          "score": 0
        },
        {
          "name": "Facilites",
          "questions": [
            {
              "id": "2d83466b-f523-4a78-b2e1-a72aafbff0eb",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mk7vkvzf8GACzIIVlDTP",
              "score": 0,
              "englishname": "Kitchen exhaust-hood and duct work system are professionally cleaned",
              "arabicname": "هل يتم تنظيف نظام شفاط المطبخ ومجاري الهواء بشكل احترافي؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم تنظيف نظام شفاط المطبخ ومجاري الهواء بشكل احترافي"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "professionally cleaned"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا يتم التنظيف بشكل اخترافي في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "not properly cleaned in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "not properly cleaned"
                  ],
                  "arabicchoices": [
                    "لا يتم التنظيف بشكل اخترافي"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "2d83466b-f523-4a78-b2e1-a72aafbff0eb",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "uWUR4P1pSF4BXJvhhozj",
              "score": 0,
              "englishname": "Kitchen exhaust-hood and duct work system are professionally maintained",
              "arabicname": "هل يتم صيانة نظام شفاط المطبخ ومجاري الهواء بشكل احترافي؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم ًصيانة نظام شفاط المطبخ ومجاري الهواء بشكل احترافي"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "uct work system are professionally well-maintained"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "نظام شفاط المطبخ ومجاري الهواء غير مُصانفي بعض الاماكن"
                  ],
                  "englishchoices": [
                    "not properly maintained in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "not properly maintained"
                  ],
                  "arabicchoices": [
                    "نظام شفاط المطبخ ومجاري الهواء غير مُصان"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "e475fd1b-bcd6-4872-8b98-bfb16d9a6e94",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mk7vkvzf8GACzIIVlDTP",
              "score": 0,
              "englishname": "Ice Machines are clean",
              "arabicname": "هل ماكينات الثلج نظيفة وفي حالة جيدة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "ماكينات الثلج نظيفة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    " ice machines are clean"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "ماكينات الثلج غير نظيفة في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "ice machines are not clean in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "ice machines are not clean"
                  ],
                  "arabicchoices": [
                    "ماكينات الثلج غير نظيفة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "e475fd1b-bcd6-4872-8b98-bfb16d9a6e94",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "uWUR4P1pSF4BXJvhhozj",
              "score": 0,
              "englishname": "Ice Machines are maintained",
              "arabicname": "هل ماكينات الثلج حالة جيدة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "ماكينات الثلج مصانة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    " ice machines are Maintained"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "ماكينات الثلج غير مصانة في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "ice machines are not maintained in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "ice machines are not maintained"
                  ],
                  "arabicchoices": [
                    "ماكينات الثلج غير مصان"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "6f0c95de-95c8-45aa-afe6-a697e07e5260",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mk7vkvzf8GACzIIVlDTP",
              "score": 0,
              "englishname": "Floors, walls, and ceilings are clean and free from excessive dust, debris, and standing water",
              "arabicname": "هل الأرضيات والجدران والأسقف نظيفة وخالية من الغبار الزائد والحطام والمياه الراكدة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "الأرضيات والجدران والأسقف نظيفة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    " floors, walls, and ceilings are clean"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "الأرضيات أو الجدران أو الأسقف متسخة في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "floors, walls, or ceilings are dirty in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "floors, walls, or ceilings are dirty"
                  ],
                  "arabicchoices": [
                    "الأرضيات أو الجدران أو الأسقف متسخة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "6f0c95de-95c8-45aa-afe6-a697e07e5260",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "uWUR4P1pSF4BXJvhhozj",
              "score": 0,
              "englishname": "Floors, walls, and ceilings are in good condition/repair",
              "arabicname": "هل الأرضيات والجدران والأسقف في حالة جيدة/مُصلحة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "الأرضيات والجدران والأسقف فى حالة جيدة ومصانه"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    " floors, walls, and ceilings are in good condition and maintained"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "الأرضيات أو الجدران أو الأسقف غير جيدة في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "floors, walls, or ceilings are not maintained in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "floors, walls, or ceilings are not maintained"
                  ],
                  "arabicchoices": [
                    "الأرضيات أو الجدران أو الأسقف ليست فى حالة جيدة وتحتاج الي صيانة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "b956594a-d354-4a79-805c-47c3a7cc65f9",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mk7vkvzf8GACzIIVlDTP",
              "score": 0,
              "englishname": "Ventilation adequate, vents, fan guards, and filters clean",
              "arabicname": "هل التهوية كافية، والمنافذ، وحمايات المروحة، والفلاتر نظيفة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "التهوية كافية، والمنافذ وحمايات المروحة والفلاتر نظيفة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "ventilation is adequate, and vents, fan guards, and filters are clean"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "التهوية أو الفلاتر غير نظيفة أو غير كافية في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "ventilation or filters are not clean or adequate in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "ventilation or filters are not clean or adequate."
                  ],
                  "arabicchoices": [
                    "التهوية أو الفلاتر غير نظيفة أو غير كافية"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "2f4b6282-90e8-4cdc-87a9-ad52c296b673",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "uWUR4P1pSF4BXJvhhozj",
              "score": 0,
              "englishname": "Back dock and entryway are insect and rodent-proof",
              "arabicname": "هل منطقة الدخول الخلفية ومداخل المخازن محمية من الحشرات والقوارض؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "جيدة و محمية"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "protected"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "غير محمية في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "Not protected in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "Not protected "
                  ],
                  "arabicchoices": [
                    "غير محمية"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "5ec672f4-5970-43fc-b3d8-bad25d0814d2",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mk7vkvzf8GACzIIVlDTP",
              "score": 0,
              "englishname": "Dumpster areas are kept clean and organized",
              "arabicname": "هل مناطق الحاويات (القمامة) نظيفة ومنظمة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "مناطق الحاويات نظيفة ومنظمة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "dumpster areas are clean and well-organized"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "مناطق الحاويات غير نظيفة أو غير منظمة "
                  ],
                  "englishchoices": [
                    "dumpster areas are not clean or organized"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "dumpster areas are not clean or organized."
                  ],
                  "arabicchoices": [
                    "مناطق الحاويات غير نظيفة أو غير منظمة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "d200928b-0996-4105-bcca-3f2c8d6c4993",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mk7vkvzf8GACzIIVlDTP",
              "score": 0,
              "englishname": "Hand washing facilities in food handling areas are used only for that purpose",
              "arabicname": "هل تُستخدم مرافق غسل اليدين في مناطق التعامل مع الطعام لهذا الغرض فقط؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "تُستخدم مرافق غسل اليدين في مناطق التعامل مع الطعام لهذا الغرض فقط"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "hand washing facilities are used solely for hand washing in food handling areas"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "يتم استخدام مرافق غسل اليدين لأغراض أخرى في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "hand washing facilities are being used for other purposes in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "hand washing facilities are being used for other purposes"
                  ],
                  "arabicchoices": [
                    "يتم استخدام مرافق غسل اليدين لأغراض أخرى"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "418da418-9af2-4785-abc2-9f633a30fbc2",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "uWUR4P1pSF4BXJvhhozj",
              "score": 0,
              "englishname": "Dishwashing machines properly maintained and operated",
              "arabicname": "هل يتم صيانة وتشغيل آلات غسل الصحون بشكل صحيح؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم صيانة وتشغيل آلات غسل الصحون بشكل صحيح"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "dishwashing machines are well-maintained and properly operated"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "آلات غسل الصحون غير مُصانة أو غير مُشغلة في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "dishwashing machines are not properly maintained in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "dishwashing machines are not properly maintained"
                  ],
                  "arabicchoices": [
                    "آلات غسل الصحون غير مُصانة أو غير مُشغلة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "57f61b43-ab2f-457b-b3e5-87f8d7daecaf",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mk7vkvzf8GACzIIVlDTP",
              "score": 0,
              "englishname": "Chemical supplies - Use and storage meets standards",
              "arabicname": "هل يتوافق استخدام وتخزين المواد الكيميائية مع المعايير؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم استخدام وتخزين المواد الكيميائية وفقاً للمعايير"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "chemical supplies are used and stored in accordance with standards"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "المواد الكيميائية غير مخزنة أو مستخدمة بشكل صحيح"
                  ],
                  "englishchoices": [
                    "chemical supplies are not stored or used correctly"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "chemical supplies are not stored or used correctly"
                  ],
                  "arabicchoices": [
                    "المواد الكيميائية غير مخزنة أو مستخدمة بشكل صحيح"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "cb083d01-71d5-402b-901f-d7b7823b2028",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "uWUR4P1pSF4BXJvhhozj",
              "score": 0,
              "englishname": "Facilities for manual washing and sanitizing of equipment and utensils properly maintained",
              "arabicname": "هل يتم صيانة مرافق الغسيل اليدوي وتعقيم المعدات والأواني بشكل صحيح؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم صيانة بشكل صحيح"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "properly maintained"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا يتم صيانة بشكل صحيح في كل الاماكن"
                  ],
                  "englishchoices": [
                    "improperly maintained in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "improperly maintained"
                  ],
                  "arabicchoices": [
                    "لا يتم صيانة بشكل صحيح"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "f8c6cf7c-17d3-4f5b-8fb9-3d740d1ee115",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "2h46scLjHZ0OGzqyaE6G",
              "score": 0,
              "englishname": "Food and food contact packaging must be properly stored at a minimum of 15 centimeters (6”) off the floor",
              "arabicname": "هل يتم تخزين الطعام وتغليف المواد الملامسة للطعام بشكل صحيح على ارتفاع لا يقل عن 15 سم (6 إنش) عن الأرض؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "مخزن بشكل صحيح"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    " properly stored"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "غير مخزن بشكل صحيح"
                  ],
                  "englishchoices": [
                    "improperly store"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "improperly stored"
                  ],
                  "arabicchoices": [
                    "غير مخزن بشكل صحيح"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "aa64694f-8699-4315-8dc3-18d41a1118d5",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "2h46scLjHZ0OGzqyaE6G",
              "score": 0,
              "englishname": " Ensure proper implementation of the FIFO principles in storage areas",
              "arabicname": "هل يتم تنفيذ مبدأ (FIFO) بشكل صحيح في مناطق تخزين الطعام؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم الالتزام بالقاعدة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "principle is properly implemented"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "غير متبع بشكل صحيح"
                  ],
                  "englishchoices": [
                    "principle is not properly followed"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "principle is not properly followed"
                  ],
                  "arabicchoices": [
                    "غير متبع بشكل صحيح"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "e47df904-0a51-4d6e-b7a9-53fe116c9de7",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": " Ensure proper implementation of the FIFO principles in food-production",
              "arabicname": "هل يتم تنفيذ مبدأ (FIFO) بشكل صحيح في مناطق إنتاج الطعام؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم الالتزام بالقاعدة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "principle is properly implemented"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "غير متبع بشكل صحيح"
                  ],
                  "englishchoices": [
                    "principle is not properly followed"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "principle is not properly followed"
                  ],
                  "arabicchoices": [
                    "غير متبع بشكل صحيح"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "c7ab4e28-3102-4eb1-9e1e-4df377467e86",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "uWUR4P1pSF4BXJvhhozj",
              "score": 0,
              "englishname": "Refrigerators and freezers maintain required temperatures",
              "arabicname": "هل تحافظ الثلاجات والمجمدات على درجات الحرارة المطلوبة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "تحافظ الثلاجات والمجمدات على درجات الحرارة المطلوبة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "refrigerators and freezers are maintaining the required temperatures."
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "الثلاجات لا تحافظ على درجات الحرارة المطلوبة فى بعض الاماكن",
                    " المجمدات لا تحافظ على درجات الحرارة المطلوبة في بعض الاماكن"
                  ],
                  "englishchoices": [
                    " Refrigerator are not maintaining the required temperatures in some places",
                    " freezers are not maintaining the required temperatures in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    " Refrigerator are not maintaining the required temperatures",
                    " freezers are not maintaining the required temperatures"
                  ],
                  "arabicchoices": [
                    "الثلاجات لا تحافظ على درجات الحرارة المطلوبة",
                    " المجمدات لا تحافظ على درجات الحرارة المطلوبة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "79e9501a-6ce7-4ccf-a3fc-76f1a172d0d6",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Cold potentially hazardous foods maintained at 41°F (5°C) or below in all cold holding devices",
              "arabicname": "هل يتم حفظ الأطعمة الباردة القابلة للتلف عند درجة حرارة 41°F (5°C) أو أقل في جميع أجهزة التبريد؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم حفظ الأطعمة عند درجة الحرارة المطلوبة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "maintained at the required temperature"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا يتم حفظ الأطعمة عند درجة الحرارة المطلوبة في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "Not maintained at the required temperature in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "Not maintained at the required temperature"
                  ],
                  "arabicchoices": [
                    "لا يتم حفظ الأطعمة عند درجة الحرارة المطلوبة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "eaa111f4-0580-4086-8ec5-a6a7de3d5315",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Hot potentially hazardous foods maintained at 135°F (57°C) or above in all hot holding devices",
              "arabicname": "هل يتم حفظ الأطعمة الساخنة القابلة للتلف عند درجة حرارة 135°F (57°C) أو أعلى في جميع أجهزة التسخين؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم حفظ الأطعمة عند درجة الحرارة المطلوبة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "maintained at the required temperature"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا يتم حفظ الأطعمة عند درجة الحرارة المطلوبة في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "Not maintained at the required temperature in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "Not maintained at the required temperature"
                  ],
                  "arabicchoices": [
                    "لا يتم حفظ الأطعمة عند درجة الحرارة المطلوبة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            }
          ],
          "score": 0
        },
        {
          "name": "Personal",
          "questions": [
            {
              "id": "71b73cdd-c323-411b-819b-eb7c70867b12",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Food handlers understand required cooking temperatures or know local legal required cooking temperatures if more stringent",
              "arabicname": "هل يفهم العاملون في إعداد الطعام درجات الحرارة المطلوبة للطهي أو يعرفون المتطلبات القانونية المحلية إذا كانت أكثر صرامة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يفهم العاملون في إعداد الطعام درجات الحرارة المطلوبة للطهي ويلتزمون بالمتطلبات القانونية المحلية"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "food handlers understand the required cooking temperatures and comply with local legal requirements"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "بعض العاملون في إعداد الطعام لا يفهم درجات الحرارة المطلوبة بشكل كامل"
                  ],
                  "englishchoices": [
                    "Some food handlers do not fully understand the required cooking temperatures"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "food handlers do not fully understand the required cooking temperatures"
                  ],
                  "arabicchoices": [
                    "لا يفهم العاملون في إعداد الطعام درجات الحرارة المطلوبة بشكل كامل"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "cee24832-d4cd-4766-91f5-a192efae13e4",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mIeoyFGFeJ9EwAtBSciM",
              "score": 0,
              "englishname": "Hotels must purchase ground/minced beef offered undercooked from vendors that comply with Marriott’s microbiological standards or grind beef on property using HACCP form",
              "arabicname": "هل تشتري الفنادق اللحم المفروم أو المقدّم نصف مطهو من موردين يلتزمون بمعايير ماريوت الميكروبيولوجية أو يتم طحن اللحم في الموقع باستخدام نموذج HACCP؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم شراء من موردين معتمدين"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "purchased from approved vendors "
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "بعض الموردين غير معتمدين"
                  ],
                  "englishchoices": [
                    "Some suppliers are not approved"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "Does not meet Marriott’s standard"
                  ],
                  "arabicchoices": [
                    "موردين غير معتمدين"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "bbb831ad-8b28-4415-ac76-0d1163dc2514",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mIeoyFGFeJ9EwAtBSciM",
              "score": 0,
              "englishname": "Food must come from commercial suppliers, and food/packaging must be in sound condition when received. Shellfish tags must be retained for 90 days",
              "arabicname": "هل يأتي الطعام من موردين تجاريين، وهل تكون حالة الطعام/التغليف سليمة عند الاستلام؟ وهل يتم الاحتفاظ بعلامات المحار لمدة 90 يوماً؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم الحصول على الطعام من موردين تجاريين، ويكون في حالة سليمة عند الاستلام"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "food is sourced from commercial suppliers, received in sound condition"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "بعض الاغذية لا يتم توريدها من مورد معتمد",
                    "بعض القشريات لا يتم الاحتفاظ بعلامات لها "
                  ],
                  "englishchoices": [
                    "Some food is not always sourced from commercial suppliers",
                    "Some shellfish tags are not retained"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "food is not always sourced from commercial suppliers",
                    "shellfish tags are not retained"
                  ],
                  "arabicchoices": [
                    "لا يتم دائماً الحصول على الطعام من موردين تجاريين",
                    "لا يتم الاحتفاظ بعلامات القشريات"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "6709e8ab-2f8a-47c5-b071-02a72234e137",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Date marking is applied at the time of preparation to potentially hazardous foods, and commercial food products are not held or sold past expiration dates",
              "arabicname": "هل يتم وضع علامات التاريخ عند تحضير الأطعمة الخطرة المحتملة، وهل يتم الالتزام بتاريخ انتهاء الصلاحية للمنتجات التجارية؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "تم وضع علامات التاريخ بشكل صحيح"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "date marking is applied correctly,"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا يتم وضع علامات التاريخ بشكل منتظم في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "date marking is inconsistent in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "date marking is inconsistent",
                    "expired products have been found"
                  ],
                  "arabicchoices": [
                    "لا يتم وضع علامات التاريخ بشكل منتظم",
                    "تم العثور على منتجات منتهية الصلاحية"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "8ac9849c-7fab-4b34-bc2e-f9973488a210",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Food and food contact surfaces protected from potential microbiological, physical, and chemical hazards",
              "arabicname": "هل يتم حماية الطعام والأسطح الملامسة للطعام من المخاطر الميكروبيولوجية أو الفيزيائية أو الكيميائية المحتملة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم حماية الطعام والأسطح الملامسة له من جميع المخاطر المحتملة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "food and contact surfaces are well-protected from all potential hazards"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "بعض الاغذية غير محمية و معرضه لمصادر المخاطر"
                  ],
                  "englishchoices": [
                    "Some food and contact surfaces are not protected from all potential hazards"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "food and contact surfaces are not protected from all potential hazards"
                  ],
                  "arabicchoices": [
                    "الاغذية غير محمية و معرضه لمصادر المخاطر"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "948cd14b-68fa-4c5f-b089-d5f6f2417ba2",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Potentially hazardous foods properly thawed",
              "arabicname": "هل يتم إذابة الأطعمة الخطرة المحتملة بشكل صحيح؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم إذابة الأطعمة الخطرة المحتملة بشكل صحيح"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "potentially hazardous foods are thawed properly"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا يتم إذابة الأطعمة الخطرة بشكل صحيح"
                  ],
                  "englishchoices": [
                    "hazardous foods are not thawed properly"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "hazardous foods are not thawed properly"
                  ],
                  "arabicchoices": [
                    "لا يتم إذابة الأطعمة الخطرة بشكل صحيح"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "8dea01c0-7162-496f-ac78-3d9cc59998b3",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "XhoOGhvmTigEvjwem1HI",
              "score": 0,
              "englishname": "In-use utensils (including ice scoops) properly handled and stored in a sanitary manner. Handles of utensils stored in the product do not touch the product and extend out of the container for moist product",
              "arabicname": "هل يتم التعامل مع الأدوات المستخدمة (بما في ذلك مغارف الثلج) وتخزينها بطريقة صحية؟ وهل مقابض الأدوات المخزنة في المنتج لا تلامس المنتج وتمتد خارج الحاوية للمنتجات الرطبة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم التعامل مع الأدوات المستخدمة وتخزينها بشكل صحيح"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "in-use utensils are handled and stored properly"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا يتم التعامل مع الأدوات المستخدمة أو تخزينها بشكل صحيح في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "in-use utensils are not properly handled or stored in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "in-use utensils are not properly handled or stored"
                  ],
                  "arabicchoices": [
                    "لا يتم التعامل مع الأدوات المستخدمة أو تخزينها بشكل صحيح"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "71c206c2-e9c9-46b4-af89-243d83042684",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Cutting board policy meets standards",
              "arabicname": "هل تتوافق سياسة ألواح التقطيع مع المعايير؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "تتوافق سياسة ألواح التقطيع مع المعايير"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "cutting board policy fully meets the standards"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا تتوافق سياسة ألواح التقطيع مع المعايير في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "cutting board policy does not meet the standards in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "cutting board policy does not meet the standards"
                  ],
                  "arabicchoices": [
                    "لا تتوافق سياسة ألواح التقطيع مع المعايير"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "96f46f0f-f099-44ed-8726-926d971642bf",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "uWUR4P1pSF4BXJvhhozj",
              "score": 0,
              "englishname": "Food contact surfaces of equipment and utensils durable, non-toxic, easily cleanable, and in good condition",
              "arabicname": "هل أسطح المعدات والأدوات الملامسة للطعام متينة، وغير سامة، وسهلة التنظيف، وفي حالة جيدة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "أسطح المعدات والأدوات الملامسة للطعام متينة، وغير سامة، وسهلة التنظيف، وفي حالة جيدة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "food contact surfaces are durable, non-toxic, easily cleanable, and in good condition."
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا تتوافق أسطح المعدات الملامسة للطعام مع المعايير في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "food contact surfaces do not meet the standards in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "food contact surfaces do not meet the standards"
                  ],
                  "arabicchoices": [
                    "لا تتوافق أسطح المعدات الملامسة للطعام مع المعايير"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "56e58b18-72f1-4b5c-99a1-af99aef7ead0",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mk7vkvzf8GACzIIVlDTP",
              "score": 0,
              "englishname": "Equipment and utensils properly cleaned, such as sides of sinks, door handles and gaskets, sliding door tracks, shelves, racks, etc.",
              "arabicname": "هل يتم تنظيف المعدات والأدوات بشكل صحيح، مثل جوانب الأحواض ومقابض الأبواب والحشيات، ومسارات الأبواب المنزلقة، والأرفف، والرفوف، وما إلى ذلك؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم تنظيف المعدات والأدوات وصيانتها بشكل صحيح"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "equipment and utensils are properly cleaned and maintained"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا يتم تنظيف المعدات والأدوات بشكل صحيح في بعض الاماكن"
                  ],
                  "englishchoices": [
                    " equipment and utensils are not properly cleaned in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    " equipment and utensils are not properly cleaned"
                  ],
                  "arabicchoices": [
                    "لا يتم تنظيف المعدات والأدوات بشكل صحيح"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "177bd0ed-d3bf-4e67-b13d-34e6dc5b7016",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mk7vkvzf8GACzIIVlDTP",
              "score": 0,
              "englishname": "Chemical sanitizer solutions at proper concentration and temperature per label instructions",
              "arabicname": "هل محاليل المعقمات الكيميائية تُستخدم بتركيز ودرجة حرارة مناسبين وفقاً لتعليمات الملصق؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم استخدام محاليل المعقمات الكيميائية بتركيز ودرجة حرارة مناسبين وفقاً للتعليمات"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "chemical sanitizer solutions are used at the proper concentration and temperature as instructed."
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا يتم استخدام محاليل المعقمات الكيميائية وفقاً للتعليمات في بعض الاماكن"
                  ],
                  "englishchoices": [
                    "sanitizer solutions are not used as per label instructions in some places"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "sanitizer solutions are not used as per label instructions"
                  ],
                  "arabicchoices": [
                    "لا يتم استخدام محاليل المعقمات الكيميائية وفقاً للتعليمات"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "255b5c6e-f85b-4933-b643-405e650fe624",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mk7vkvzf8GACzIIVlDTP",
              "score": 0,
              "englishname": "Sanitizer test kits readily available for use for all available kinds of sanitizers",
              "arabicname": "هل مجموعات اختبار المعقمات متوفرة بسهولة للاستخدام لجميع أنواع المعقمات المتوفرة؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "مجموعات اختبار المعقمات متوفرة بسهولة ويتم استخدامها حسب الحاجة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "sanitizer test kits are readily available and used as required"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "مجموعات اختبار المعقمات غير متوفرة بسهولة"
                  ],
                  "englishchoices": [
                    "sanitizer test kits are not readily available"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "sanitizer test kits are not readily available"
                  ],
                  "arabicchoices": [
                    "مجموعات اختبار المعقمات غير متوفرة بسهولة"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            }
          ],
          "score": 0
        },
        {
          "name": "Personal Hygiene",
          "questions": [
            {
              "id": "013c703a-081e-49cd-b29f-9ed5adc498bd",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Hands washed frequently and appropriately after potential contamination",
              "arabicname": "هل يتم غسل اليدين بشكل متكرر وبالطريقة الصحيحة بعد التعرض للتلوث المحتمل؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم غسل اليدين بشكل متكرر وبالطريقة الصحيحة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "Hands are washed frequently and appropriately"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "بعض العاملين لا يقوموا بغسيل الايدي بشكل مستمر",
                    "بعض العاملين لا يقوموا بغسيل الايدي بشكل صحيح"
                  ],
                  "englishchoices": [
                    "Some food handler not  washed hands frequently",
                    "Some food handler not  washed hands appropriatley"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "hands are not washed frequently",
                    "hands are not washed appropriately"
                  ],
                  "arabicchoices": [
                    "لا يتم غسل اليدين بشكل متكرر ",
                    "لا يتم غسل اليدين بالطريقة الصحيحة "
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "80e67ba0-7e92-48ad-8087-e5d171c48dc8",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "No bare hand contact observed with ready-to-eat food except with a GFS Variance",
              "arabicname": "هل لم يتم ملاحظة أي تلامس مباشر باليد مع الطعام الجاهز للأكل باستثناء ما يسمح به تصريح GFS?",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "لا يوجد أي تلامس مباشر باليد مع الطعام الجاهز للأكل",
                    "يتم اتباع جميع البروتوكولات وفقاً لتصريح GFS"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "No bare-hand contact with ready-to-eat food",
                    "All protocols are followed as per the GFS "
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "تم ملاحظة تلامس مباشر باليد مع الطعام الجاهز للأكل فى بعض الاماكن",
                    "تم اتخاذ إجراءات تصحيحية فورية"
                  ],
                  "englishchoices": [
                    "bare hand contact was observed in some area with ready-to-eat food ",
                    "Immediate corrective measures are being taken."
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "bare hand contact was observed with ready-to-eat food",
                    "No Immediate corrective measures are being taken."
                  ],
                  "arabicchoices": [
                    "تم ملاحظة تلامس مباشر باليد مع الطعام الجاهز للأكل",
                    "لم يتم اتخاذ إجراءات تصحيحية فورية"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "387da998-9b1c-4f88-8d30-a3ca12275129",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Persons displaying contagious symptoms are restricted or excluded from working around exposed food, utensils, or equipment, and hotels must display Associate Illness exclusion/restriction Guidelines",
              "arabicname": "هل يتم تقييد أو استبعاد الأشخاص الذين تظهر عليهم أعراض معدية من العمل بالقرب من الطعام المكشوف أو الأدوات أو المعدات، وهل تعرض الفنادق إرشادات تقييد/استبعاد الأمراض؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم تقييد أو استبعاد الموظفين الذين تظهر عليهم أعراض معدية من العمل بالقرب من الطعام",
                    "الارشادات متواجدة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "employees showing contagious symptoms are restricted or excluded from working around food",
                    "Guideline are displayed"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "يوجد بعض الاشخاص الذين يعانون من امراض معدية او منقولة الي الغذاء في بعض اماكن تحضير الطعام"
                  ],
                  "englishchoices": [
                    "employees showing contagious symptoms are present in some food area"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    "Employees with contagious symptoms are not restricted or excluded",
                    "Guidelines are not displayed"
                  ],
                  "arabicchoices": [
                    "لا يتم تقييد أو استبعاد الموظفين الذين تظهر عليهم أعراض معدية",
                    "لا يتم عرض الإرشادات"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "a0083524-5b73-4b9c-9794-ff992bd23a46",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "434MsJA3iYxRduwxDVZ7",
              "score": 0,
              "englishname": "Eating, drinking, and tobacco use restricted to non-food areas",
              "arabicname": "هل يتم تقييد الأكل والشرب واستخدام التبغ في المناطق غير المخصصة للطعام؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "يتم تقييد الأكل والشرب واستخدام التبغ"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "eating, drinking, and tobacco use are restricted"
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "لا يتم تقيد الاكل و الشرب و التبغ في بعض المناطق"
                  ],
                  "englishchoices": [
                    "eating, drinking, and tobacco use are not restricted in some kitchens"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    " eating, drinking, or tobacco use was observed in food areas"
                  ],
                  "arabicchoices": [
                    "تم ملاحظة الأكل أو الشرب أو استخدام التبغ في مناطق الطعام"
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            },
            {
              "id": "c239816c-3789-4a2b-b38c-3d34347ebc81",
              "answer": false,
              "assignto": "",
              "answerindex": "",
              "duedate": "",
              "departmentid": "mk7vkvzf8GACzIIVlDTP",
              "score": 0,
              "englishname": "Written procedures must be on hand that outline clean-up/disinfecting procedures and tools used for vomitus events",
              "arabicname": "هل توجد إجراءات مكتوبة متوفرة تحدد إجراءات التنظيف والتعقيم والأدوات المستخدمة في حالات القيء؟",
              "images": [],
              "answers": [
                {
                  "arabicchoices": [
                    "تتوفر إجراءات مكتوبة"
                  ],
                  "score": 5,
                  "name": "c",
                  "englishchoices": [
                    "written procedures are available."
                  ]
                },
                {
                  "name": "m",
                  "score": 2,
                  "arabicchoices": [
                    "يوجد اجراءات لكن غير مكتملة",
                    "يوجد اجراءات لكن غير محدثة"
                  ],
                  "englishchoices": [
                    "available but not completed",
                    "available but not updated"
                  ]
                },
                {
                  "score": 0,
                  "name": "nc",
                  "englishchoices": [
                    " written procedures not available"
                  ],
                  "arabicchoices": [
                    "لا توجد إجراءات مكتوبة لعمليات التنظيف والتعقيم "
                  ]
                },
                {
                  "name": "na"
                }
              ],
              "photourl": [],
              "englishansweredchoices": [],
              "arabicansweredchoices": []
            }
          ],
          "score": 0
        }
      ],
      "id": "",
      "name": "Global Food Safety Audit",
      "url": "",
      "iconmd": "md-american-football"
    }
    this.firestore.collection("modules").add(data).then((res) => {

    }, (error) => {
        throw error; // This will propagate the error to GlobalErrorHandler
    });
}
  getclientbyid(id) {
    this.moduleref = []
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('clients', ref => ref.where('id', '==', id)).get().subscribe(async client => {
        try {
          var clients = await Promise.all(client.docs.map(async (doc) => {
            var item = doc.data() as any;
            console.log(item);
            localStorage.setItem('ClientData', JSON.stringify(item));
            let imagelogo
            if (item.clientlogo) {
                imagelogo = await this.getImageData(item.clientlogo);
            }
            else {
                imagelogo = ""
            }
            localStorage.setItem("ClientLogo", imagelogo);
            const moduleRefs = await Promise.all(item.moudlerf.map(async (element, index) => {
              const doc = await element.id.get();
              console.log(doc.data() as any, "doc.data() as any");
              if (doc.exists) {
                let moduleDoc = doc.data();
                moduleDoc["id"] = doc.id;
                return moduleDoc;
              }
            }));

            item.moudlerf = moduleRefs.filter(ref => ref); 
            item.id = doc.id;
            return item;
          }));

          resolve(clients);
        } catch (error) {
          reject(error);
        } finally {
          this.LoaderServiceService.hide();
        }
      }, error => {
        reject(error);
        this.LoaderServiceService.hide();
      });
    });
  }

  // private getImageData(url: string): Promise<string> {
  //   return fetch(url)
  //     .then(response => response.blob())  // Fetch image as Blob
  //     .then(blob => {
  //       return new Promise<string>((resolve, reject) => {
  //         const reader = new FileReader();
          
  //         reader.onloadend = () => {
  //           resolve(reader.result as string);  // Return the Base64 string
  //         };
          
  //         reader.onerror = (error) => {
  //           reject(error);  // Reject on FileReader error
  //         };
          
  //         reader.readAsDataURL(blob);  // Read the Blob as Base64
  //       });
  //     });
  // }

  // private getImageData(url: string): Promise<string> {
  //   return new Promise<string>((resolve, reject) => {
  //     this.http.get(url, { responseType: 'blob' }).subscribe(response => {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         resolve(reader.result as string);
  //       };
  //       reader.onerror = () => {
  //         reject(reader.error);
  //       };
  //       reader.readAsDataURL(response);
  //     }, error => {
  //       reject(error);
  //     });
  //   });
  // }

   private getImageData(url: string): Promise<string> {
    return fetch(url)
      .then(response => response.blob())  // Fetch image as Blob
      .then(blob => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          
          reader.onloadend = () => {
            resolve(reader.result as string);  // Return the Base64 string
          };
          
          reader.onerror = (error) => {
            reject(error);  // Reject on FileReader error
          };
          
          reader.readAsDataURL(blob);  // Read the Blob as Base64
        });
      });
  }
  



  //Dynmic Filter 

  // getActionPlanPage(filters){
  //   let query: any = this.firestore.collection('actionplan', ref => {
  //     let dynamicQuery:any = ref;
  //     // Dynamically apply filters based on the provided object
  //     Object.keys(filters).forEach(key => {
  //       if (filters[key].value !== '') {

  //         var keyFb=key
  //         if(key=="startdate" || key== "enddate"){
  //           keyFb='date'
  //         }
  //         if(filters[key].value=="Open"){
  //           keyFb="done"
  //           filters[key].value='false'
  //         }
  //         else if(filters[key].value=="Close"){
  //           keyFb="done"
  //           filters[key].value='true'
  //         }

  //         dynamicQuery = dynamicQuery.where(keyFb, filters[key].operator, filters[key].value);
  //         console.log(dynamicQuery,"dynamicQuery")

  //       }
  //     });
  //     return dynamicQuery;
  //   });
  //   // Execute the query and return the result
  //    query.get().subscribe(res=>{
  //     console.log(res,"dfgdfgdf")
  //     return new Promise<any>((resolve, reject) => {
  //       resolve(res);

  //     })
  //    });

  // }

  getActionPlanPage(filterobj, clientId): Promise<any> {
    this.LoaderServiceService.show();
    return new Promise<any>((resolve, reject) => {
      console.log(filterobj.startdate.toISOString())
      if (filterobj.assignerid && filterobj.state !== '') {
        this.firestore.collection('actionplan', ref => ref.where('moduleid', '==', filterobj.moduleid)
          .where('clientid', '==', clientId)
          .where('departmentid', '==', filterobj.departmentid)
          .where('branchid', '==', filterobj.branchid)
          .where('assignerid', '==', filterobj.assignerid)
          .where('done', '==', filterobj.state)
          .where('date', '>=', filterobj.startdate.toISOString())
          .where('date', '<=', filterobj.enddate.toISOString())).get().subscribe((assign) => {
            var data = assign.docs.map(element => {
              var item = element.data() as any;
              item.id = element.id;
              return item;
            })
            resolve(data);
            this.LoaderServiceService.hide();

          }, error => {
            reject(error);
            this.LoaderServiceService.hide();

          });
      }
      else if (filterobj.assignerid && filterobj.state == '') {
        this.firestore.collection('actionplan', ref => ref.where('moduleid', '==', filterobj.moduleid)
          .where('clientid', '==', clientId)
          .where('departmentid', '==', filterobj.departmentid)
          .where('branchid', '==', filterobj.branchid)
          .where('assignerid', '==', filterobj.assignerid)
          .where('date', '>=', filterobj.startdate.toISOString())
          .where('date', '<=', filterobj.enddate.toISOString())).get().subscribe((assign) => {
            var data = assign.docs.map(element => {
              var item = element.data() as any;
              item.id = element.id;
              return item;
            })
            resolve(data);
            this.LoaderServiceService.hide();

          }, error => {
            reject(error);
            this.LoaderServiceService.hide();

          });
      }
      else if (!filterobj.assignerid && filterobj.state) {
        this.firestore.collection('actionplan', ref => ref.where('moduleid', '==', filterobj.moduleid)
          .where('clientid', '==', clientId)
          .where('departmentid', '==', filterobj.departmentid)
          .where('branchid', '==', filterobj.branchid)
          .where('done', '==', filterobj.state)
          .where('date', '>=', filterobj.startdate.toISOString())
          .where('date', '<=', filterobj.enddate.toISOString())).get().subscribe((assign) => {
            var data = assign.docs.map(element => {
              var item = element.data() as any;
              item.id = element.id;
              return item;
            })
            resolve(data);
            this.LoaderServiceService.hide();

          }, error => {
            reject(error);
            this.LoaderServiceService.hide();

          });
      }
      else if (!filterobj.assignerid && !filterobj.state) {
        this.firestore.collection('actionplan', ref => ref.where('moduleid', '==', filterobj.moduleid)
          .where('clientid', '==', clientId)
          .where('departmentid', '==', filterobj.departmentid)
          .where('branchid', '==', filterobj.branchid)
          .where('date', '>=', filterobj.startdate.toISOString())
          .where('date', '<=', filterobj.enddate.toISOString())).get().subscribe((assign) => {
            var data = assign.docs.map(element => {
              var item = element.data() as any;
              item.id = element.id;
              return item;
            })
            resolve(data);
            this.LoaderServiceService.hide();

          }, error => {
            reject(error);
            this.LoaderServiceService.hide();

          });
      }


    });
  }

  getAnalysisAuditTotalScoreMonthly(filterobj, clientId): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('AnalysisAuditTotalScoreMonthly', ref => {
        let query = ref.where('clientid', '==', clientId);
        // Check if any of the branches in the document's array matches the ones in filterobj.branches
        // Check if the moduleid is in the filterobj.moduleid array
        query = query.where('moduleid', '==', filterobj.moduleid);
        return query;
      }).get().subscribe((assign) => {
        console.log(assign)
        var data = assign.docs.map(element => {
          var item = element.data();
          // item.id = element.id;
          return item;
        });
        this.LoaderServiceService.hide();
        resolve(data);
        // this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        // this.LoaderServiceService.hide();

      });
      // if(filterobj.branches!==''){
      //   this.firestore.collection('AnalysisAuditTotalScoreMonthly', ref => ref
      //   .where('clientid', '==', clientId)
      //   .where('moduleid', 'in', filterobj.moudelid)).get().subscribe((assign) => {
      //     var data = assign.docs.map(element => {
      //       var item = element.data() as any;
      //       item.id = element.id;
      //       return item;
      //     })
      //     resolve(data);
      //   }, error => {
      //     reject(error);
      //   });
      // }
      // else if (filterobj.branches==''){
      //   this.firestore.collection('AnalysisAuditTotalScoreMonthly', ref => ref
      //   .where('clientid', '==', clientId)
      //   .where('moduleid', 'in', filterobj.moudelid)
      //   .where('branches', 'in', filterobj.branches)
      //   ).get().subscribe((assign) => {
      //     var data = assign.docs.map(element => {
      //       var item = element.data() as any;
      //       item.id = element.id;
      //       return item;
      //     })
      //     resolve(data);
      //   }, error => {
      //     reject(error);
      //   });
      // }

    });
  }

  getAnalysisAuditTotalScoreYearly(filterobj, clientId): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('AnalysisAuditTotalScoreYearly', ref => {
        let query = ref.where('clientid', '==', clientId);
        // Check if any of the branches in the document's array matches the ones in filterobj.branches
        // Check if the moduleid is in the filterobj.moduleid array
        query = query.where('moduleid', '==', filterobj.moduleid);
        return query;
      }).get().subscribe((assign) => {
        var data = assign.docs.map(element => {
          var item = element.data();
          // item.id = element.id;
          return item;
        });
        console.log(data)
        this.LoaderServiceService.hide();
        resolve(data);

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }











  getAnalysisAuditDetailedScoreMonthly(filterobj, clientId): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('AnalysisAuditDetailedScoreMonthly', ref => {
        let query = ref.where('clientid', '==', clientId);
        // Check if any of the branches in the document's array matches the ones in filterobj.branches
        // Check if the moduleid is in the filterobj.moduleid array
        query = query.where('moduleid', '==', filterobj.moduleid);
        return query;
      }).get().subscribe((assign) => {
        var data = assign.docs.map(element => {
          var item = element.data();
          // item.id = element.id;
          return item;
        });
        console.log(data)
        this.LoaderServiceService.hide();
        resolve(data);

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }

  getAnalysisAuditDetailedScoreYearly(filterobj, clientId): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('AnalysisAuditDetailedScoreYearly', ref => {
        let query = ref.where('clientid', '==', clientId);
        // Check if any of the branches in the document's array matches the ones in filterobj.branches
        // Check if the moduleid is in the filterobj.moduleid array
        query = query.where('moduleid', '==', filterobj.moduleid);
        return query;
      }).get().subscribe((assign) => {
        var data = assign.docs.map(element => {
          var item = element.data();
          // item.id = element.id;
          return item;
        });
        console.log(data)
        this.LoaderServiceService.hide();
        resolve(data);
      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }




  async getDepartmentKpiMonthly(filterobj: any, clientId: string, dates: { month: number, year: number }[]): Promise<any> {
    this.LoaderServiceService.show();

    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    const promises = dates.map(date => {
      return this.firestore.collection('AnalysisDepartmentKpiMonthly', ref => {
        let query = ref.where('clientid', '==', clientId)
          .where('moduleid', '==', filterobj.moduleid)
          .where('branch', '==', filterobj.branches)
          .where('month', '==', date.month)
          .where('year', '==', date.year)
        return query;
      }).get().toPromise();
    });

    try {
      const results = await Promise.all(promises);
      console.log(results);
      const data = results.map(res => {
        return res.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });
      console.log(data);

      return data;
    } catch (error) {
      this.LoaderServiceService.hide();

      console.error('Error fetching or processing data:', error);
      throw error;
    }


  }




  async getDepartmentKpiYearlyCompare(filterobj: any, clientId: string, dates: { year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    // Create an array of promises for each branch and date combination
    const branchPromises = filterobj.branches.map((branch) => {
      const datePromises = dates.map(date => {
        return this.firestore.collection('AnalysisDepartmentKpiYearly', ref => {
          let query = ref.where('clientid', '==', clientId)
            .where('moduleid', '==', filterobj.moduleid)
            .where('branch', '==', branch)
            .where('year', '==', date.year);
          return query;
        }).get().toPromise();
      });

      // Return a promise that resolves when all date queries for the current branch are complete
      return Promise.all(datePromises);
    });

    try {
      // Wait for all branch queries to complete
      const branchResults = await Promise.all(branchPromises);
      console.log(branchResults);

      // Flatten and process the results
      const data = branchResults.flat().map(dateResults => {
        return dateResults.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });

      console.log(data);
      this.LoaderServiceService.hide();

      return data;
    } catch (error) {
      this.LoaderServiceService.hide();

      console.error('Error fetching or processing data:', error);
      throw error;
    }
  }

  async getDepartmentKpiMonthlyCompare(filterobj: any, clientId: string, dates: { month: number, year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    // Create an array of promises for each branch and date combination
    const branchPromises = filterobj.branches.map((branch) => {
      const datePromises = dates.map(date => {
        return this.firestore.collection('AnalysisDepartmentKpiMonthly', ref => {
          let query = ref.where('clientid', '==', clientId)
            .where('moduleid', '==', filterobj.moduleid)
            .where('branch', '==', branch)
            .where('month', '==', date.month)
            .where('year', '==', date.year);
          return query;
        }).get().toPromise();
      });

      // Return a promise that resolves when all date queries for the current branch are complete
      return Promise.all(datePromises);
    });

    try {
      // Wait for all branch queries to complete
      const branchResults = await Promise.all(branchPromises);
      console.log(branchResults);
      // Flatten and process the results
      const data = branchResults.flat().map(dateResults => {
        return dateResults.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });
      console.log(data);
      this.LoaderServiceService.hide();
      return data;
    } catch (error) {
      console.error('Error fetching or processing data:', error);
      this.LoaderServiceService.hide();
      throw error;
    }
  }




  async getTaskCompilationMonthlyCompare(filterobj: any, clientId: string, dates: { month: number, year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    // Create an array of promises for each branch and date combination
    const branchPromises = filterobj.branches.map((branch) => {
      const datePromises = dates.map(date => {
        return this.firestore.collection('AnalysisTaskCompilationMonthly', ref => {
          let query = ref.where('clientid', '==', clientId)
            .where('moduleid', '==', filterobj.moduleid)
            .where('branchid', '==', branch)
            .where('month', '==', date.month)
            .where('year', '==', date.year);
          return query;
        }).get().toPromise();
      });

      // Return a promise that resolves when all date queries for the current branch are complete
      return Promise.all(datePromises);
    });

    try {
      // Wait for all branch queries to complete
      const branchResults = await Promise.all(branchPromises);
      console.log(branchResults);

      // Flatten and process the results
      const data = branchResults.flat().map(dateResults => {
        return dateResults.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });

      console.log(data);
      this.LoaderServiceService.hide();

      return data;
    } catch (error) {
      this.LoaderServiceService.hide();

      console.error('Error fetching or processing data:', error);
      this.LoaderServiceService.hide();
      throw error;
    }
  }



  async getTaskCompilationYearlyCompare(filterobj: any, clientId: string, dates: { year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();


    // Create an array of promises for each branch and date combination
    const branchPromises = filterobj.branches.map((branch) => {
      const datePromises = dates.map(date => {
        return this.firestore.collection('AnalysisTaskCompilationYearly', ref => {
          let query = ref.where('clientid', '==', clientId)
            .where('moduleid', '==', filterobj.moduleid)
            .where('branchid', '==', branch)
            .where('year', '==', date.year);
          return query;
        }).get().toPromise();
      });

      // Return a promise that resolves when all date queries for the current branch are complete
      return Promise.all(datePromises);
    });

    try {
      // Wait for all branch queries to complete
      const branchResults = await Promise.all(branchPromises);
      console.log(branchResults);

      // Flatten and process the results
      const data = branchResults.flat().map(dateResults => {
        return dateResults.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });

      console.log(data);
      this.LoaderServiceService.hide();

      return data;
    } catch (error) {
      console.error('Error fetching or processing data:', error);
      this.LoaderServiceService.hide();
      throw error;
    }
  }








  async getStandardCompilationMonthlyCompare(filterobj: any, clientId: string, dates: { month: number, year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    // Create an array of promises for each branch and date combination
    const branchPromises = filterobj.branches.map((branch) => {
      const datePromises = dates.map(date => {
        return this.firestore.collection('AnalysisStandrdCompilationMonthly', ref => {
          let query = ref.where('clientid', '==', clientId)
            .where('moduleid', '==', filterobj.moduleid)
            .where('branchid', '==', branch)
            .where('month', '==', date.month)
            .where('year', '==', date.year);
          return query;
        }).get().toPromise();
      });

      // Return a promise that resolves when all date queries for the current branch are complete
      return Promise.all(datePromises);
    });

    try {
      // Wait for all branch queries to complete
      const branchResults = await Promise.all(branchPromises);
      console.log(branchResults);

      // Flatten and process the results
      const data = branchResults.flat().map(dateResults => {
        return dateResults.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });

      console.log(data);
      this.LoaderServiceService.hide();

      return data;
    } catch (error) {
      this.LoaderServiceService.hide();

      console.error('Error fetching or processing data:', error);
      this.LoaderServiceService.hide();
      throw error;
    }
  }



  async getStandardCompilationYearlyCompare(filterobj: any, clientId: string, dates: { year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    // Create an array of promises for each branch and date combination
    const branchPromises = filterobj.branches.map((branch) => {
      const datePromises = dates.map(date => {
        return this.firestore.collection('AnalysisStandrdCompilationYearly', ref => {
          let query = ref.where('clientid', '==', clientId)
            .where('moduleid', '==', filterobj.moduleid)
            .where('branchid', '==', branch)
            .where('year', '==', date.year);
          return query;
        }).get().toPromise();
      });

      // Return a promise that resolves when all date queries for the current branch are complete
      return Promise.all(datePromises);
    });

    try {
      // Wait for all branch queries to complete
      const branchResults = await Promise.all(branchPromises);
      console.log(branchResults);

      // Flatten and process the results
      const data = branchResults.flat().map(dateResults => {
        return dateResults.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });

      console.log(data);
      this.LoaderServiceService.hide();

      return data;
    } catch (error) {
      this.LoaderServiceService.hide();

      console.error('Error fetching or processing data:', error);
      this.LoaderServiceService.hide();
      throw error;
    }
  }








  async getDepartmentKpiYearly(filterobj: any, clientId: string, dates: { year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    const promises = dates.map(date => {
      return this.firestore.collection('AnalysisDepartmentKpiYearly', ref => {
        let query = ref.where('clientid', '==', clientId)
          .where('moduleid', '==', filterobj.moduleid)
          .where('branch', '==', filterobj.branches)
          .where('year', '==', date.year)
        return query;
      }).get().toPromise();
    });

    try {
      const results = await Promise.all(promises);
      console.log(results);
      const data = results.map(res => {
        return res.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });
      console.log(data);

      return data;
    } catch (error) {
      console.error('Error fetching or processing data:', error);
      this.LoaderServiceService.hide();
      throw error;
    }


  }


  async getTaskCompilationMonthly(filterobj: any, clientId: string, dates: { month: number, year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    const promises = dates.map(date => {
      return this.firestore.collection('AnalysisTaskCompilationMonthly', ref => {
        let query = ref.where('clientid', '==', clientId)
          .where('moduleid', '==', filterobj.moduleid)
          .where('branchid', '==', filterobj.branches)
          .where('month', '==', date.month)
          .where('year', '==', date.year)
        return query;
      }).get().toPromise();
    });

    try {
      const results = await Promise.all(promises);
      console.log(results);
      const data = results.map(res => {
        return res.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });
      console.log(data);
      this.LoaderServiceService.hide();
      return data;
    } catch (error) {
      console.error('Error fetching or processing data:', error);
      this.LoaderServiceService.hide();
      throw error;
    }


  }


  async getTaskCompilationYearly(filterobj: any, clientId: string, dates: { year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    const promises = dates.map(date => {
      return this.firestore.collection('AnalysisTaskCompilationYearly', ref => {
        let query = ref.where('clientid', '==', clientId)
          .where('moduleid', '==', filterobj.moduleid)
          .where('branchid', '==', filterobj.branches)
          .where('year', '==', date.year)
        return query;
      }).get().toPromise();
    });

    try {
      const results = await Promise.all(promises);
      console.log(results);
      const data = results.map(res => {
        return res.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });
      console.log(data);
      this.LoaderServiceService.hide();

      return data;
    } catch (error) {
      this.LoaderServiceService.hide();

      console.error('Error fetching or processing data:', error);
      throw error;
    }


  }



  async getStandrdCompilationMonthly(filterobj: any, clientId: string, dates: { month: number, year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    const promises = dates.map(date => {
      return this.firestore.collection('AnalysisStandrdCompilationMonthly', ref => {
        let query = ref.where('clientid', '==', clientId)
          .where('moduleid', '==', filterobj.moduleid)
          .where('branchid', '==', filterobj.branches)
          .where('month', '==', date.month)
          .where('year', '==', date.year)
        return query;
      }).get().toPromise();
    });

    try {
      const results = await Promise.all(promises);
      console.log(results);
      const data = results.map(res => {
        return res.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });
      console.log(data);

      return data;
    } catch (error) {
      this.LoaderServiceService.hide();

      console.error('Error fetching or processing data:', error);
      throw error;
    }


  }


  async getStandrdCompilationYearly(filterobj: any, clientId: string, dates: { year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    const promises = dates.map(date => {
      return this.firestore.collection('AnalysisStandrdCompilationYearly', ref => {
        let query = ref.where('clientid', '==', clientId)
          .where('moduleid', '==', filterobj.moduleid)
          .where('branchid', '==', filterobj.branches)
          .where('year', '==', date.year)
        return query;
      }).get().toPromise();
    });

    try {
      const results = await Promise.all(promises);
      console.log(results);
      const data = results.map(res => {
        return res.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });
      console.log(data);

      return data;
    } catch (error) {
      this.LoaderServiceService.hide();

      console.error('Error fetching or processing data:', error);
      throw error;
    }


  }


  async CommonMistakesMonthly(filterobj: any, clientId: string, dates: { month: number, year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    const promises = dates.map(date => {
      return this.firestore.collection('CommonMistakesMonthly', ref => {
        let query = ref.where('clientid', '==', clientId)
          .where('moduleid', '==', filterobj.moduleid)
          .where('branchid', '==', filterobj.branches)
          .where('month', '==', date.month)
          .where('year', '==', date.year)
        return query;
      }).get().toPromise();
    });

    try {
      const results = await Promise.all(promises);
      console.log(results);
      const data = results.map(res => {
        return res.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });
      console.log(data);
      this.LoaderServiceService.hide();

      return data;
    } catch (error) {
      this.LoaderServiceService.hide();

      console.error('Error fetching or processing data:', error);
      throw error;
    }


  }

  async CommonMistakesYearly(filterobj: any, clientId: string, dates: { year: number }[]): Promise<any> {
    console.log("Filter Object:", filterobj);
    console.log("Client ID:", clientId);
    console.log("Dates:", dates);
    this.LoaderServiceService.show();

    const promises = dates.map(date => {
      return this.firestore.collection('CommonMistakesYearly', ref => {
        let query = ref.where('clientid', '==', clientId)
          .where('moduleid', '==', filterobj.moduleid)
          .where('branchid', '==', filterobj.branches)
          .where('year', '==', date.year)
        return query;
      }).get().toPromise();
    });

    try {
      const results = await Promise.all(promises);
      console.log(results);
      const data = results.map(res => {
        return res.docs.map(element => {
          var item = element.data();
          // Uncomment if you need the document ID
          // item.id = element.id;
          return item;
        });
      });
      console.log(data);
      this.LoaderServiceService.hide();

      return data;
    } catch (error) {
      this.LoaderServiceService.hide();

      console.error('Error fetching or processing data:', error);
      throw error;
    }


  }



  // getActionPlanbyAssignerid(id){
  //   return new Promise<any>((resolve, reject) => {

  //     this.firestore.collection('actionplan', ref => ref.where('assignerid', '==',id)).get().subscribe(async (actionplan) => {
  //       let data = actionplan.docs.map(element => {
  //         let actionplan = element.data() as any;
  //         actionplan.id = element.id;
  //         return actionplan;

  //       });
  //       // let data = venue.docs.map(element => {
  //       //   var item = element.data() as any;
  //       //   item.id = element.id;
  //       //   return item;
  //   //    })
  //       resolve(data);
  //     }, error => {
  //       reject(error);
  //     });
  //   });
  // }
  getActionPlanbyAssignerid(id: any, pageSize: number, lastDocument: any, pageNumber: number): Observable<any[]> {
    this.LoaderServiceService.show();

    return new Observable<any[]>(observer => {
      if (lastDocument) {
        const query = this.firestore.collection('actionplan', ref =>
          ref.where('assignerid', '==', id).where('done', '==', 'false')
            .orderBy('date', 'desc')
            .startAfter(lastDocument.date)
            .limit(pageSize)
        );
        query.get().pipe(
          map(snapshot => {
            const data = [];
            if (!snapshot.empty) {
              // lastDocument = snapshot.docs[snapshot.docs.length - 1] ;
              snapshot.forEach((doc) => {
                const docData: any = doc.data();
                docData.id = doc.id;
                data.push(docData);
              });
              this.LoaderServiceService.hide();
              return data;
            } else {
              console.log('No more documents to fetch');
              this.LoaderServiceService.hide();
              return [];
            }
          })
        ).subscribe(data => observer.next(data));
      }
      else {
        const query = this.firestore.collection('actionplan', ref =>
          ref.where('assignerid', '==', id).where('done', '==', 'false')
            .orderBy('date', 'desc')
            .limit(pageSize)
        );
        query.get().pipe(
          map(snapshot => {
            const data = [];
            if (!snapshot.empty) {
              // lastDocument = snapshot.docs[snapshot.docs.length - 1] ;
              snapshot.forEach(doc => {
                const docData: any = doc.data();

                docData.id = doc.id;

                console.log(docData)
                data.push(docData);
              });
              this.LoaderServiceService.hide();

              return data;
            } else {
              console.log('No more documents to fetch');
              this.LoaderServiceService.hide();
              return [];
            }
          })
        ).subscribe(data => observer.next(data));
      }





    });


  }
  // getTotalPages(collectionName: string, pageSize: number): Observable<number> {
  //   return new Observable<number>(observer => {
  //     this.firestore.collection(collectionName).get().subscribe(snapshot => {
  //       const totalCount = snapshot.size; // Total number of documents in the collection
  //       const totalPages = Math.ceil(totalCount / pageSize);
  //       observer.next(totalPages);

  //     }, error => {
  //       observer.error(error);
  //     });
  //   });
  // }




  getModuleByid(id) {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('modules', ref => ref.where('id', '==', id)).get().subscribe(async (Moduels) => {
        let data = Moduels.docs.map(element => {
          let Moduels = element.data() as any;
          Moduels.id = element.id;
          return Moduels;

        });
        resolve(data);
        this.LoaderServiceService.hide();
      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  getbranchesbyclientid(id) {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('branches', ref => ref.where('clientid', '==', id)).get().subscribe((branches) => {
        var data = branches.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  GetBranchById(id) {
    this.LoaderServiceService.show();
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('branches').doc(id).get().subscribe((branches) => {
        const data = branches.data(); // Access the data from the document
        console.log(data); // Inspect the data
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  getClusterbyclientid(id) {
    this.LoaderServiceService.show();
    return new Promise<any>((resolve, reject) => {
      const query = ref => ref.where('clientid', '==', id).where('role', "==", 'cluster')
      this.firestore.collection('users', query).get().subscribe((branches) => {
        var data = branches.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }

  getGMbyclientid(id) {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      const query = ref => ref.where('clientid', '==', id).where('role', "==", 'gm')
      this.firestore.collection('users', query).get().subscribe((branches) => {
        var data = branches.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }

  getCeobyclientid(id) {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      const query = ref => ref.where('clientid', '==', id).where('role', "==", 'ceo')
      this.firestore.collection('users', query).get().subscribe((branches) => {
        var data = branches.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();
      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  getAuditerbyclientid(id) {
    this.LoaderServiceService.show();
    return new Promise<any>((resolve, reject) => {
      const query = ref => ref.where('clientid', '==', id).where('role', "==", 'auditer')
      this.firestore.collection('users', query).get().subscribe((branches) => {
        var data = branches.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();
      });

    });
  }
  getMeasurerbyclientid(id) {
    this.LoaderServiceService.show();
    return new Promise<any>((resolve, reject) => {
      const query = ref => ref.where('clientid', '==', id).where('role', "==", 'measurer')
      this.firestore.collection('users', query).get().subscribe((branches) => {
        var data = branches.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  getAuditerbyId(id) {
    this.LoaderServiceService.show();
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('audits').doc(id).get().subscribe((documentSnapshot) => {
        if (documentSnapshot.exists) {
          const data = documentSnapshot.data(); // Access the data from the document
          console.log(data); // Inspect the data
          resolve(data);
          this.LoaderServiceService.hide();

        } else {
          console.log("No such document exists!");
          this.LoaderServiceService.hide();

          resolve(null); // or reject if you want to treat it as an error
        }
      }, error => {
        console.error("Error fetching document:", error);
        this.LoaderServiceService.hide();
        reject(error);
      });

    });
  }
  getbranchesAuditbyid(id) {
    this.LoaderServiceService.show();
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('audits', ref => ref.where('brancheid', '==', id)).get().subscribe((branches) => {
        var data = branches.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }

  getActionPlanByAuditid(id) {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('actionplan', ref => ref.where('auditid', '==', id)).get().subscribe((actionplan) => {
        var data = actionplan.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  getauditbyassignemail() {
    this.LoaderServiceService.show();
    return new Promise<any>((resolve, reject) => {
      //let clientref = this.firestore.collection('clients').doc(id).ref;
      //console.log("clientref",clientref)
      this.firestore.collection('audits', ref => ref.where('module.categories.$[name]', '==', 'infrastructure')).get().subscribe(async (audit) => {
        let data = audit.docs.map(element => {
          let audit = element.data() as any;
          console.log(audit, "audit")
          audit.id = element.id;
          return audit;

        });
        // let data = venue.docs.map(element => {
        //   var item = element.data() as any;
        //   item.id = element.id;
        //   return item;
        //    })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }
  removeclient(item) {
    this.LoaderServiceService.show();
    this.firestore.collection("clients").doc(item).delete().then(value => {
      console.log(value)
      this.LoaderServiceService.hide();

    })
      .catch(error => {
        this.LoaderServiceService.hide();

        console.log('Something went wrong: ', error);
      });


    //   this.firestore.collection(`clients/${item.id}`,
    //   ref => ref.orderBy('order')).valueChanges().subscribe(client => {
    //     client.map((question: clients) => {
    //       this.firestore.doc(`clients/${item.id}`).delete()
    //           .catch(error => {console.log(error); })
    //           .then(() => console.log(`Deleting client done`));
    //   });
    // });
  }
  removeDocument(item) {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection("files").doc(item).delete().then(value => {
        console.log(value)
        resolve(value)

      })

    })


  }
  async getAuditsForReports(startDate, EndDate, moduleid, branchid) {


    this.LoaderServiceService.show();

    return new Promise<any[]>(async (resolve, reject) => {
      const userInfo = JSON.parse(localStorage.getItem("currentuser"));
      try {


        await this.firestore.collection('audits', ref => ref
          .where('module.id', '==', moduleid)
          .where('clientid', '==', userInfo.clientid)
          .where('branchid', '==', branchid)
          .orderBy('EndDate')
          .where('EndDate', '>=', startDate.toISOString())
          .where('EndDate', '<=', EndDate.toISOString())
          
        ).get().subscribe(async (audit) => {
          let data = audit.docs.map(element => {
            var item = element.data() as any;
            item.id = element.id;
            return item;
          })
          resolve(data);
          this.LoaderServiceService.hide();
        })
      } catch (error) {
        reject(error);
        this.LoaderServiceService.hide();
      }
    });

  }



  async getLastAuditOnBranch(moduleid: string): Promise<any[]> {
    this.LoaderServiceService.show();
    return new Promise<any[]>(async (resolve, reject) => {
      const userInfo = JSON.parse(localStorage.getItem("currentuser"));
      console.log(userInfo)
      try {
        if (userInfo.role === "cluster" || userInfo.role === "admin" || userInfo.role === "ceo") {
          if (userInfo.role == "admin") {
            userInfo.clientid = localStorage.getItem("userid")
          }
          const data = await this.firestore.collection('DashboardAllCharts', ref => ref
            .where('moduleid', '==', moduleid)
            .where('clientid', '==', userInfo.clientid)
            .where('last', '==', true)
          ).get().toPromise();

          const res = [];
          for (const element of data.docs) {
            const item = element.data() as any;
            const branch = await this.GetBranchById(item.branchid);
            item["branchname"] = branch.name;
            res.push(item);
          }
          resolve(res);
          this.LoaderServiceService.hide();

        } else {
          if (userInfo.branchesid && Array.isArray(userInfo.branchesid) && userInfo.branchesid.length > 0) {
            const data = await this.firestore.collection('DashboardAllCharts', ref => ref
              .where('moduleid', '==', moduleid)
              .where('clientid', '==', userInfo.clientid)
              .where('last', '==', true)
              .where('branchid', 'in', userInfo.branchesid)
            ).get().toPromise();

            const res = [];
            for (const element of data.docs) {
              const item = element.data() as any;
              const branch = await this.GetBranchById(item.branchid);
              item["branchname"] = branch.name;
              res.push(item);
            }
            resolve(res);
            this.LoaderServiceService.hide();


          } else {
            resolve([]);
            this.LoaderServiceService.hide();

          }
        }
      } catch (error) {
        reject(error);
        this.LoaderServiceService.hide();

      }

    });
  }
  async getAuditByIdForReportCharts(auditid: any): Promise<any[]> {
    this.LoaderServiceService.show();

    return new Promise<any[]>(async (resolve, reject) => {
      try {
        const data = await this.firestore.collection('DashboardAllCharts', ref => ref
          .where('auditid', '==', auditid)
        ).get().toPromise();

        const res = [];
        for (const element of data.docs) {
          const item = element.data() as any;
          const branch = await this.GetBranchById(item.branchid);
          item["branchname"] = branch.name;
          res.push(item);
        }
        resolve(res);
        // this.LoaderServiceService.hide();


      } catch (error) {
        reject(error);
        // this.LoaderServiceService.hide();

      }
    });
  }



  async GetUserWithID(id: string): Promise<any> {
    this.LoaderServiceService.show();

    try {
      const docRef = this.firestore.collection('users').doc(id);
      const docSnapshot = await docRef.get().toPromise();

      if (docSnapshot.exists) {
        // Document exists, return the document data
        // this.LoaderServiceService.hide();

        return docSnapshot.data();

      } else {
        // Document does not exist
        // this.LoaderServiceService.hide();

        throw new Error('Document not found');
      }
    } catch (error) {
      // Handle errors
      // this.LoaderServiceService.hide();

      console.error('Error fetching document:', error);
      throw error;
    }


  }
  fireEmpty() {
    Swal.fire({
      title: "Empty",
      text: "No data found",
      icon: 'warning'
    });
  }


  async addPackage(packages) {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('packages').add(packages).then(res => {
        resolve(res);
        this.LoaderServiceService.hide();
      }, error => {
        reject(error);
        this.LoaderServiceService.hide();
      });


    });
  }

  public getallPackage(): Promise<any> {
    this.LoaderServiceService.show();

    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('packages').get().subscribe((packages) => {
        var data = packages.docs.map(element => {
          var item = element.data() as any;
          item.id = element.id;
          return item;
        })
        resolve(data);
        this.LoaderServiceService.hide();

      }, error => {
        reject(error);
        this.LoaderServiceService.hide();

      });

    });
  }

  UpdatePackage(packages, id) {

    this.LoaderServiceService.show();

    return this.firestore.collection('packages').doc(id).update(packages)
      .then(value => {
        this.LoaderServiceService.hide();
      })
      .catch(error => {
        this.LoaderServiceService.hide();

        console.log('Something went wrong: ', error);
      });

  }

  async DeletePackage(id) {
    this.LoaderServiceService.show();
    this.firestore.collection("packages").doc(id).delete().then(value => {
      console.log(value)
      this.LoaderServiceService.hide();
    })
      .catch(error => {
        this.LoaderServiceService.hide();
        console.log('Something went wrong: ', error);
      });
  }

  
  qutaCountAddUsers(clientId: string): Observable<boolean> {
    const docRef = this.firestore.collection('quotapackage', ref => ref.where('clientid', '==', clientId)).get();
    console.log(docRef);
    return new Observable<boolean>(observer => {
      docRef.subscribe(snapshot => {
        if (snapshot.docs.length > 0) {
          const doc = snapshot.docs[0];
          const data = doc.data() as any;
          const currentCount = data.countusers;
          const maxQutaUsers = data.qutausers;
          // Check if countusers is less than qutausers
          if (currentCount < maxQutaUsers) {
            // Increment countusers
            const newCount = currentCount + 1;
            // Update the document
            this.firestore.collection('quotapackage').doc(doc.id).update({ countusers: newCount })
              .then(() => {
                observer.next(true); // Successfully updated
                observer.complete();
              })
              .catch(error => {
                console.error('Error updating document: ', error);
                observer.next(false); // Update failed
                observer.complete();
              });
          } else {
            observer.next(false); // Countusers >= qutausers
            Swal.fire({
              title: 'Oops!',
              text: 'You have reached your user limit. Please upgrade your plan to add more users.',
              icon: 'error'
            });
            observer.complete();
          }
        } else {
          observer.next(false); // No document found
          observer.complete();
        }
      }, error => {
        console.error('Error retrieving documents: ', error);
        observer.next(false); // Error occurred
        observer.complete();
      });
    });
  }

  qutaCountDelUsers(clientId: string): Observable<boolean> {
    const docRef = this.firestore.collection('quotapackage', ref => ref.where('clientid', '==', clientId)).get();
    console.log(docRef);
    return new Observable<boolean>(observer => {
      docRef.subscribe(snapshot => {
        if (snapshot.docs.length > 0) {
          const doc = snapshot.docs[0];
          const data = doc.data() as any;
          const currentCount = data.countusers;
          // Check if countusers is less than qutausers
            // Increment countusers
            const newCount = currentCount - 1;
            // Update the document
            this.firestore.collection('quotapackage').doc(doc.id).update({ countusers: newCount })
              .then(() => {
                observer.next(true); // Successfully updated
                observer.complete();
              })
              .catch(error => {
                console.error('Error updating document: ', error);
                observer.next(false); // Update failed
                observer.complete();
              });
          
        } else {
          observer.next(false); // No document found
          observer.complete();
        }
      }, error => {
        console.error('Error retrieving documents: ', error);
        observer.next(false); // Error occurred
        observer.complete();
      });
    });
  }

  qutaCountAddBranches(clientId: string): Observable<boolean> {
    const docRef = this.firestore.collection('quotapackage', ref => ref.where('clientid', '==', clientId)).get();
    console.log(docRef);
    return new Observable<boolean>(observer => {
      docRef.subscribe(snapshot => {
        if (snapshot.docs.length > 0) {
          const doc = snapshot.docs[0];
          const data = doc.data() as any;
          const currentCount = data.countbranches;
          const maxQutaBranches = data.qutabranches;
          // Check if countusers is less than qutausers
          if (currentCount < maxQutaBranches) {
            // Increment countusers
            const newCount = currentCount + 1;
            // Update the document
            this.firestore.collection('quotapackage').doc(doc.id).update({ countbranches: newCount })
              .then(() => {
                observer.next(true); // Successfully updated
                observer.complete();
              })
              .catch(error => {
                console.error('Error updating document: ', error);
                observer.next(false); // Update failed
                observer.complete();
              });
          } else {
            observer.next(false); // Countusers >= qutausers
            Swal.fire({
              title: 'Oops!',
              text: 'You have reached your user limit. Please upgrade your plan to add more users.',
              icon: 'error'
            });
            observer.complete();
          }
        } else {
          observer.next(false); // No document found
          observer.complete();
        }
      }, error => {
        console.error('Error retrieving documents: ', error);
        observer.next(false); // Error occurred
        observer.complete();
      });
    });
  }

  qutaCountDelBranches(clientId: string): Observable<boolean> {
    const docRef = this.firestore.collection('quotapackage', ref => ref.where('clientid', '==', clientId)).get();
    console.log(docRef);
    return new Observable<boolean>(observer => {
      docRef.subscribe(snapshot => {
        if (snapshot.docs.length > 0) {
          const doc = snapshot.docs[0];
          const data = doc.data() as any;
          const currentCount = data.countbranches;
          // Check if countusers is less than qutausers
            // Increment countusers
            const newCount = currentCount -1;
            // Update the document
            this.firestore.collection('quotapackage').doc(doc.id).update({ countbranches: newCount })
              .then(() => {
                observer.next(true); // Successfully updated
                observer.complete();
              })
              .catch(error => {
                console.error('Error updating document: ', error);
                observer.next(false); // Update failed
                observer.complete();
              });
          } 
         else {
          observer.next(false); // No document found
          observer.complete();
        }
      }, error => {
        console.error('Error retrieving documents: ', error);
        observer.next(false); // Error occurred
        observer.complete();
      });
    });
  }

}


